import moment from 'moment';
import { DATE_FORMAT, MONTH,NEW_DATE_FORMAT_FOR_REPORT } from '../components/Common/constants';
import { Tooltip } from 'antd';
import React from 'react';

 export const bookingTrackerObj = (bookingArray) => {
  return bookingArray?.map((data) => ({
    bookingRef: data.reportData.bookingId,
    category: data.reportData.category,
    activity: data.reportData.activity,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceCountry: data.reportData.spaceCountry,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.reportData.eventStartDate !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: data.reportData.eventEndDate !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT) : 'N/A',
    eventStartTime: data.reportData.eventStartTime,
    eventEndTime: data.reportData.eventEndTime,
    month: data.reportData.month,
    year: data.reportData.year,
    guestCount: data.reportData.guest,
    days: data.reportData.days,
    hours: data.reportData.hours,
    blocks: data.reportData.blocks,
    bookingCount: data.reportData.bookingCount,
    promoCode: data.reportData.promoCode,
    bookingType: data.reportData.bookingType,
    paymentStatus: data.reportData.paymentStatus,
    bookingStatus: data.reportData.bookingStatus,
  }));
};

export const paymentRefundObj = (paymentRefundArray) => {
  return paymentRefundArray?.map((data) => ({
    bookingRef: data.reportData.bookingId,
    paymentRef: data.reportData.paymentId,
    refundRef: data.reportData.paymentRefundId,
    category: data.reportData.category,
    activity: data.reportData.activity,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceCountry: data.reportData.spaceCountry,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT) : 'N/A',
    paymentDate: data.reportData.paymentDate !== null ? moment(data.reportData.paymentDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.reportData.eventStartDate !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT) : 'N/A',
    eventEndDate: data.reportData.eventEndDate !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT) : 'N/A',
    month: data.reportData.month,
    year: data.reportData.year,
    paymentAmount: data.reportData.paymentAmount,
    transactionFee: data.reportData.transactionFee,
    receivedAmount: data.reportData.receivedAmount,
    refundDate: data.reportData.refundDate !== null ? moment(data.reportData.refundDate).format(DATE_FORMAT) : 'N/A',
    refundAmount: data.reportData.refundAmount,
    refundTransactionFee: data.reportData.refundTransactionFee,
    totalRefundValue: data.reportData.totalRefundValue,
    refundReason: data.reportData.refundReason,
    cardType: data.reportData.cartType,
    cardNumber: data.reportData.cardNumber,
    cardExpDate: data.reportData.cardExpiryDate !== null ? moment(data.reportData.cardExpiryDate).format(DATE_FORMAT) : 'N/A' ,
  }));
};

export const paymentTrackerObj = (paymentArray) => {
  return paymentArray?.map((data) => ({
    bookingRef: data.reportData.bookingId,
    paymentRef: data.reportData.paymentId,
    category: data.reportData.category,
    activity: data.reportData.activity,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceCountry: data.reportData.spaceCountry,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT) : 'N/A',
    eventStartDate: data.reportData.startDate !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT) : 'N?A',
    eventEndDate: data.reportData.eventEndDate !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT) : 'N?A',
    paymentDate: data.reportData.paymentDate !== null ? moment(data.reportData.paymentDate).format(DATE_FORMAT) : 'N/A',
    month: data.reportData.month,
    year: data.reportData.year,
    paymentAmount: data.reportData.paymentAmount,
    transactionFee: data.reportData.transactionFee,
    cardType: data.reportData.cardType,
    cardNumber: data.reportData.cardNumber,
    cardExpDate: data.reportData.cardExpiryDate !== null ? moment(data.reportData.cardExpiryDate).format(DATE_FORMAT) : 'N/A',
    paymentStatus: data.reportData.paymentStatus,
    bookingStatus: data.reportData.bookingStatus,
  }));
};

export const revenueTrackerObj = (revenueArray) => {
    return revenueArray?.map(data => ({
      bookingRef: data.reportData.bookingId,
      category: data.reportData.category,
      activity: data.reportData.activity,
      guestName: data.reportData.guestName,
      guestEmail: data.reportData.guestEmail,
      hostName: data.reportData.hostName,
      hostEmail: data.reportData.hostEmail,
      spaceId: data.reportData.spaceId,
      spaceName: data.reportData.spaceName,
      spaceCountry: data.reportData.spaceCountry,
      bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(DATE_FORMAT): 'N/A',
      paymentDate: data.reportData.paymentDate  !== null ? moment(data.reportData.paymentDate).format(DATE_FORMAT): 'N/A',
      eventStartDate: data.reportData.eventStartDate  !== null ? moment(data.reportData.eventStartDate).format(DATE_FORMAT): 'N/A',
      eventEndDate: data.reportData.eventEndDate  !== null ? moment(data.reportData.eventEndDate).format(DATE_FORMAT): 'N/A',
      month: data.reportData.month,
      year: data.reportData.year,
      baseBookingValue: data.reportData.baseBookingValue,
      hostDiscountValue: data.reportData.hostDiscountValue,
      bookingValueAfterHostDiscount: data.reportData.bookingValueAfterHostDiscount,
      taxPayableToHost: data.reportData.taxPayableToHost,
      msDiscountValue: data.reportData.msDiscountValue,
      finalBookingValue: data.reportData.finalBookingValue,
      taxPayableByGuest: data.reportData.taxPayableByGuest,
      finalGuestPaymentAmount: data.reportData.finalGuestPayableAmount,
      transactionFee: data.reportData.transactionFee,
      msCommission: data.reportData.msCommission,
      commissionValue: data.reportData.commissionValue,
      finalCommissionValue: data.reportData.finalCommissionValue,
      totalPayableToHost: data.reportData.totalPayableToHost,
      grossProfit: data.reportData.grossProfit,
      paymentStatus: data.reportData.paymentStatus,
      bookingStatus: data.reportData.bookingStatus,
    }))
}

export const spaceTrackerObj = (spaceArray) => {
  return spaceArray?.map((data) => ({
    category: data.reportData.categories?.map(category => category),
    activity: data.reportData.activities?.map(activity => activity),
    organizationName: data.reportData.organizationName,
    listedDate: data.reportData.listedDate !== null ? moment(data.reportData.listedDate).format(DATE_FORMAT) : 'N/A',
    listedMonth: data.reportData.month,
    listedYear: data.reportData.year,
    hostName: data.reportData.hostName,
    hostEmail: data.reportData.hostEmail,
    hostNumber: data.reportData.hostTelephone.map(number => number),
    spaceId: data.reportData.spaceId,
    spaceName: data.reportData.spaceName,
    spaceAddress: data.reportData.spaceAddress,
    spaceCity: data.reportData.spaceCity,
    spaceCountry: data.reportData.spaceCountry,
    maxSpaceCapacity: data.reportData.spaceCapacity.map(capacity => capacity),
    nameOnAccount: data.reportData.nameOfAccount,
    bank: data.reportData.bank,
    swiftCode: data.reportData.swiftCode,
    bankCode: data.reportData.bankCode,
    branchCode: data.reportData.branchCode,
    accountNumber: data.reportData.accountNumber,
    commission: data.reportData.commission,
    status: data.reportData.spaceStatus,
  }));
};

export const userTrackerObj = (userArray) => {
  return userArray?.map(data => ({
    firstName: data.reportData.firstName,
    lastName: data.reportData.lastName,
    email: data.reportData.email,
    spaceCountry: data.reportData.country,
    telephoneNumber: data.reportData.phoneNumbers,
    role: data.reportData.roles.map(role => role),
    host: data.reportData.host,
    signUpVia: data.reportData.signUpVia,
    dateJoined: data.reportData.dateJoined !== null ? moment(data.reportData.dateJoined).format(DATE_FORMAT) : 'N/A',
    status:  data.reportData.status
  }))
}

export const summaryTrackerObj = (summaryArray) => {
  return summaryArray?.map(data => ({
    spaceCountry: data.reportData.spaceCountry,
    bookingId: data.reportData.bookingId,
    bookingStatus: data.reportData.bookingStatus,
    bookingType: data.reportData.bookingType,
    guestCount: data.reportData.guest,
    paymentStatus: data.reportData.paymentStatus,
    bookingDate: data.reportData.bookingDate !== null ? moment(data.reportData.bookingDate).format(NEW_DATE_FORMAT_FOR_REPORT) : 'N/A',
    eventStartDate: data.reportData.startDate !== null ? moment(data.reportData.eventStartDate).format(NEW_DATE_FORMAT_FOR_REPORT) : 'N?A',
    paymentAmount:data.reportData.paymentAmount,
    cardType: data.reportData.cardType,
    guestName: data.reportData.guestName,
    guestEmail: data.reportData.guestEmail,
    activity: data.reportData.activity,
    spaceName: data.reportData.spaceName,
  }))
}

//when the data in the column is a single value
export const searchOnFilter = (value, record, dataIndex) => {
  if (record[dataIndex] === undefined || record[dataIndex] === null) {
    record[dataIndex] = '';
  }
  return value.find(
    (element) =>
      element.toString().trim().toLowerCase().includes(record[dataIndex].trim().toLowerCase()) ||
      (element === 'after' && record[dataIndex] > moment().year().toString()) ||
      (element === 'before' && record[dataIndex] < moment().year().toString())
  );
};

//when the data in the column in an array
export const arrayColumnFilter = (value, record, dataIndex) => {
  return record[dataIndex].find((data,index) => 
      value.find(element => record[dataIndex][index] === element)
    )
}

export const searchFilterForEmptyFields = (value, record, dataIndex) => {
  return value.find(element => record[dataIndex] === element);
}

export const searchTextOnFilter = (value, record, dataIndex) => {
  if(record[dataIndex] === undefined || record[dataIndex] === null) {
    record[dataIndex] = '';
  }
  return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
}

export const handleNullValues = text => {
  return <Tooltip title={text} overlayClassName="report-tooltip"> {text === null || text === '' || text === 0 ? '-' : text }</Tooltip>;
}

export const addOrUppdateArray = (array, payload, key) => {
  if(Boolean(array.length)){
      if(Boolean(array.filter(value => value[key] === payload[key]).length)){
          return array.map((item) => {
              if (item[key] !== payload[key]) {
                return item
              }
              return {...item, ...payload}
          })
      }else{
          return [...array, payload]
      }
  }else{
      return [payload]
  }
}