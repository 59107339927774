import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'antd';
import { getSummaryTrackerData } from '../ReportAction';
import {
    summaryTrackerObj,
    searchTextOnFilter,
    searchOnFilter,
    handleNullValues,
    arrayColumnFilter,
    searchFilterForEmptyFields,
    setToolTip
} from '../../../helper/ReportingHelper';
import InfiniteTable from '../../Common/Table/InfiniteTable'
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';

const SummaryTracker = ({ handleDownload, status }) => {
    const summaryTrackerData = useSelector((state) => state?.report?.reports?.summary?.data);
    const authToken = useSelector((state) => state?.auth?.loginDetails?.data?.token);

    const [dataSource, setDataSource] = useState([]);
    const [tableData, setTableData] = useState({});

    useEffect(() => {
        const formattedData = summaryTrackerObj(summaryTrackerData);
        formattedData?.length && setDataSource(formattedData);
    }, [summaryTrackerData]);

    const handleSearch = (setSelectedKeys, confirm, searchText) => {
        setSelectedKeys([searchText]);
        confirm();
    };

    const handleReset = (setSelectedKeys, clearFilters) => {
        setSelectedKeys([]);
        clearFilters();
    };

    const summeryTrackerColumns = [
        {
            key: 'spaceCountry',
            title: 'Space Country',
            dataIndex: 'spaceCountry',
            className: 'custom-width',
            width: 113,
            render: text => {
                return text === 'LK' ? 'SL' : handleNullValues(text);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <SearchFilters
                    filterType="country"
                    onChange={(filterValue) => {
                        if (filterValue.length) {
                            handleSearch(setSelectedKeys, confirm, filterValue);
                        } else {
                            handleReset(setSelectedKeys, clearFilters);
                        }
                    }}
                />
            ),
            filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
            onFilter: (value, record) => {
                return searchOnFilter(value, record, 'spaceCountry');
            },
        },
        {
            key: 'bookingRef',
            title: 'Booking Id',
            dataIndex: 'bookingId',
            className: 'custom-width',
            width: 113,
            render: (text) => {
                return handleNullValues(text);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <SearchText
                    placeholder="Search"
                    type="text"
                    onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
                    onReset={() => handleReset(setSelectedKeys, clearFilters)}
                />
            ),
            filterIcon: (filtered) => {
                return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
            },
            onFilter: (value, record) => {
                return searchTextOnFilter(value, record, 'bookingId');
            },
        },
        {
            key: 'bookingStatus',
            title: 'Booking Status',
            dataIndex: 'bookingStatus',
            className: 'custom-width',
            width: 113,
            render: text => {
                return handleNullValues(text);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <SearchFilters
                    filterType="bookingStatus"
                    onChange={(filterValue) => {
                        if (filterValue.length) {
                            handleSearch(setSelectedKeys, confirm, filterValue);
                        } else {
                            handleReset(setSelectedKeys, clearFilters);
                        }
                    }}
                />
            ),
            filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }} theme="filled" />,
            onFilter: (value, record) => {
                return searchFilterForEmptyFields(value, record, 'bookingStatus');
            },
        },
        {
            key: 'bookingType',
            title: 'Booking Type',
            dataIndex: 'bookingType',
            width: 113,
            render: text => {
                return handleNullValues(text);
            },
        },
        {
            key: 'guestCount',
            title: 'Guest(s)',
            dataIndex: 'guestCount',
            width: 113,
            render: text => {
                return handleNullValues(text);
            },
        },
        {
            key: 'paymentStatus',
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            className: 'custom-width',
            width: 113,
            render: (text) => {
                return handleNullValues(text);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <SearchFilters
                    filterType="paymentStatus"
                    onChange={(filterValue) => {
                        if (filterValue.length) {
                            handleSearch(setSelectedKeys, confirm, filterValue);
                        } else {
                            handleReset(setSelectedKeys, clearFilters);
                        }
                    }}
                />
            ),
            filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '66px' }} theme="filled" />,
            onFilter: (value, record) => {
                return searchFilterForEmptyFields(value, record, 'paymentStatus');
            },
        },
        {
            key: 'bookingDate',
            title: 'Booking Date',
            dataIndex: 'bookingDate',
            width: 113,
        },
        {
            key: 'eventStartDate',
            title: 'Event Start Date',
            dataIndex: 'eventStartDate',
            width: 113,
        },
        {
            key: 'paymentAmount',
            title: 'Payment Amount',
            dataIndex: 'paymentAmount',
            width: 113,
            render: (text) => {
                return handleNullValues(text);
            },
        },
        {
            key: 'cardType',
            title: 'Card Type',
            dataIndex: 'cardType',
            className: 'custom-width',
            width: 150,
            render: (text) => {
                let cardName;
                switch (text) {
                    case 'visa':
                        cardName = 'VISA';
                        return (
                            <div>
                                {cardName}
                            </div>
                        );
                    case 'mastercard':
                        cardName = 'MASTERCARD';
                        return (
                            <div>
                                {cardName}
                            </div>
                        );
                    case 'amex':
                        cardName = 'AMEX';
                        return (
                            <div>
                                {cardName}
                            </div>
                        );
                    default:
                        return handleNullValues(text);
                }
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <SearchFilters
                    filterType="cardType"
                    onChange={(filterValue) => {
                        if (filterValue.length) {
                            handleSearch(setSelectedKeys, confirm, filterValue);
                        } else {
                            handleReset(setSelectedKeys, clearFilters);
                        }
                    }}
                />
            ),
            filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '80px' }} theme="filled" />,
            onFilter: (value, record) => {
                return searchFilterForEmptyFields(value, record, 'cardType')
            },
        },
        {
            key: 'guestName',
            title: 'Guest Name',
            dataIndex: 'guestName',
            className: 'custom-width',
            width: 150,
            render: text => {
                return handleNullValues(text);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <SearchText
                    placeholder="Search"
                    type="text"
                    onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
                    onReset={() => handleReset(setSelectedKeys, clearFilters)}
                />
            ),
            filterIcon: (filtered) => {
                return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>
            },
            onFilter: (value, record) => {
                return searchTextOnFilter(value, record, 'guestName');
            },
        },
        {
            key: 'guestEmail',
            title: 'Guest Email',
            dataIndex: 'guestEmail',
            width: 150,
            render: text => {
                return handleNullValues(text);
            },
        },
        {
            key: 'activity',
            title: 'Activity',
            dataIndex: 'activity',
            className: 'custom-width',
            width:113,
            render: text => {
              return handleNullValues(text);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
              <SearchFilters
                filterType="activity"
                onChange={(filterValue) => {
                  if (filterValue.length) {
                    handleSearch(setSelectedKeys, confirm, filterValue);
                  } else {
                    handleReset(setSelectedKeys, clearFilters);
                  }
                }}
              />
            ),
            filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
            onFilter: (value, record) => {
              return searchOnFilter(value, record, 'activity');
            },
          },
        {
            key: 'spaceName',
            title: 'Space Name',
            dataIndex: 'spaceName',
            className: 'custom-width',
            width: 113,
            render: text => {
                return handleNullValues(text);
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <SearchText
                    placeholder="Search"
                    type="text"
                    onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
                    onReset={() => handleReset(setSelectedKeys, clearFilters)}
                />
            ),
            filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>,
            onFilter: (value, record) => {
                return searchTextOnFilter(value, record, 'spaceName');
            },
        }
    ];

    const downloadCSV = () => {
        handleDownload({ ...tableData, reportType: 'summary-tracker', totalDataLength: dataSource?.length, token: authToken })
    }

    return (
        <div>
            <HeaderButton handleDownload={downloadCSV} status={status} />

            <InfiniteTable
                columns={summeryTrackerColumns}
                dataSource={dataSource}
                tableDispatch={getSummaryTrackerData}
                props={{ increaseValue: 10, token: authToken }}
                tableData={setTableData}
                scroll={{ y: 330, x: 500 }}
            />
        </div>
    );
};

export default withTracker('SUMMARY_TRACKER')(SummaryTracker);
