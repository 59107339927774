export const CURRENCY_SYMBOL = 'SGD';
// Payment type
export const DAILY = 'Daily';
export const MONTHLY = 'Monthly';
export const WEEKLY = 'Weekly';
export const HOURLY = 'Hourly';
export const BLOCK = 'Block';
export const HOURS = 'hours';
export const MINUTES = 'minutes';

export const PERCENTAGE = 'PERCENTAGE';
export const VALUE = 'VALUE';
//Space Type
export const WORK = 'work';
export const MEET = 'meet';
export const TRAIN = 'train';

//Bank detials
export const MS_BANK_TRANSFER_EMAIL = 'info@millionspaces.com';
export const MS_HOTLINE = '0117 811 811'

//Space Type capitalized.
export const WORK_CAPITALIZED = 'Work';
export const MEET_CAPITALIZED = 'Meet';
export const TRAIN_CAPITALIZED = 'Train';

//Space Type With First Letter Capital
export const WORK_C = 'Work';
export const MEET_C = 'Meet';
export const TRAIN_C = 'Train';

export const WORK_HOTD = "WORK_HOTD";
export const YEAR_MONTH_DAY_FORMAT = "YYYY-MM-DD";
export const NEW_DATE_FORMAT_FOR_REPORT = 'DD MMM YYYY';

//Venue Type
export const HOT_DESK = 'individual workspace';
export const DEDICATED_DESK = 'dedicated desk';
export const PRIVATE_OFFICE = 'Private Office';
export const BOOTH = 'booth';
export const PHOTOSHOOT = 'Photoshoot';

//Activity codes
export const PRIVATE_OFFICE_CODE = 'WORK_PRIV'

export const HOT_DESK_C = 'Individual Workspace';
export const DEDICATED_DESK_C = 'Dedicated Desk';
export const PRIVATE_OFFICE_C = 'Private Office';
export const BOOTH_C = 'Booth';
export const HOT_DESK_CAPITALIZED = "Individual Workspace";
export const DEDICATED_DESK_CAPITALIZED = "Individual Workspace";
export const MEETING_CAPITALIZED = 'Meeting';
export const BOARDROOM_CAPITALIZED = 'Boardroom';
export const INTERVIEW_CAPITALIZED = 'Interview';
export const OFFSITE_CAPITALIZED = 'Offsite';
export const WORKSHOP_CAPITALIZED = 'Workshop';

export const maxSeating = 1000000;
export const searchBoxCharacterLimitOfLocation = 30;

export const characterLimitMediaCard = 170;
export const defaultCountry = 'Singapore';
export const defaultShortCountryCode = 'SG';
export const defaultCurrency = 'SGD';

export const DATE_FORMAT_REVERSE = 'DD-MM-YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FOR_VISITS = 'MM-DD-YYYY'
export const DATE_FORMAT_FOR_REVIEW = 'DD/MM/YY';
export const MONTH = 'MMMM';
export const TIME_FORMAT = 'h:mm a';
export const TIME_FORMAT_HOURS = 'HH:mm'
export const TIME_FORMAT_FOR_VISITS = 'HH:mm:A';
export const DATE_TIME_FORMAT_WITHOUT_UTC = 'YYYY-MM-DDTHH:mm:ss';
export const MOMENT_DEFAULT_FORMAT = 'YYYY-MM-DD';
export const TIME_NOON = '12:00';
export const ADMIN = 'role.admin';
export const USER = 'role.user';
export const USER_HOST = 'role.user.host';
export const AM = 'am'
export const PM = 'pm'

export const EDIT = 'Edit';
export const SEARCH_BAR = 'Search';
export const CREATE = 'Create';
export const CLEAR = 'Clear';
export const MANUAL_BOOKING = 'MANUAL_BOOKING';
export const SEATING_ARRANGEMENT = 'Seating Arrangement';

export const COVID_SL_URL = 'https://covid19.gov.lk/guidelines.html';
export const COVID_SG_URL = 'https://www.moh.gov.sg/covid-19';

export const PAY_HERE_RETURN_PATH = '/space/payhere'

export const LOCATION_WITH_SINGLE_SPACE = 'unique_single_locations';
export const LOCATION_WITH_MULTIPLE_SPACES = 'locations_with_multiple_spaces';

export const MINUS = 'minus';
export const PLUS = 'plus';

export const SINGAPORE = 'Singapore';
export const INITIAL_SEARCHBAR_COUNTRY = 'Singapore ';
export const CONFLICT = 'CONFLICT';
export const NOTICE_PERIOD_ERROR = 'NOTICE_PERIOD_ERROR';

export const ACTIVE = 'ACTIVE';
export const COMPLETED = "COMPLETED"
export const INITIATED = "INITIATED"
export const SEARCH_BAR_DATE_PLACEHOLDER = 'When?';
export const SEARCH_PARAMS = {
  COUNTRY: 'country',
  LOCATION_OR_SPACE: 'locationOrSpace',
  LOCATION: 'location',
  ACTIVITY: 'eventType',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  LAT: 'lat',
  LNG: 'lng',
};

export const RESPONSE_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const PAYMENT_METHODS = {
  CARD: 'card',
  BANK: 'bank'
};

export const COUNTRY_CODES = {
  SINGAPORE: { code: '+65', country_shortCode: 'SG', country_longCode: 'Singapore', lat: 1.352083, lng: 103.819836, currency: 'SGD', country_path: 'Singapore' },
  SRI_LANKA: { code: '+94', country_shortCode: 'LK', country_longCode: 'Sri Lanka', lat: 6.901608599999999, lng: 80.0087746, currency: 'LKR', country_path: 'Sri-Lanka'},
  AUSTRALIA: { code: '+61', country_shortCode: 'AUS', country_longCode: 'Australia', lat: -37.970235, lng: 145.054753, currency: 'AUD', country_path: 'Australia'},
};

export const COUNTRIES = {
  SRI_LANKA: 'Sri Lanka',
  SINGAPORE: 'Singapore',
  Australia: 'Australia',
};


export const COVID_MESSAGE = `All of our partners featured on MillionSpaces are working very hard to provide you a safe and pleasant experience within their properties. 
As a community, we must all do our part to ensure we, and those around us, are safe at all times. 
Please respect the basic safety measures that are safe distancing, wearing a mask and frequent hand washing. 
You can check here for the latest news and updates. Thank you and stay safe.`;

//Analytics constants

export const CATEGORIES = {
  SEARCH : 'Search',
  HOME_PAGE: 'Homepage',
  FEATURED_SPACES: 'Featured Spaces',
  SPACE_PROFILE: 'Space Profile',
  BOOKING_FLOW: 'Booking Flow'
};

export const ACTIONS = {
  SEARCH_HOME : 'SearchedSpacesFromHome',
  SEARCH_RESULT : 'SearchedSpacesFromSpaceResults',
  SPACE_VIEW_FROM_RESULT : 'ViewedSpaceFromSearchResults',
  SPACE_VIEW_HOME_PAGE: 'ViewedSpaceFromHomepageLatestSpaces',
  RESULT_VIEW_FROM_FEATURED_SECTION: 'ViewedResultsFromFeaturedSpacesSection',
  RESULT_VIEW_FROM_FEATURED_FOOTER: 'ViewedResultsFromFeaturedSpacesInFooter',
  VIEW_SPACE_FROM_FEATURED: 'ViewedSpaceFromFeaturedSpaces',
  VIEW_SPACE_PROFILE: 'ViewedSpaceProfile',
  VIEW_SIMILAR_SPACES_FROM_SPACE_PROFILE: 'ViewedSimilarSpaceFromSpaceProfile',
  CONTINUE_TO_BOOKING_CONFIRMATION: 'ContinuedToBookingConfirmationPage',
  COMPLETE_BOOKING_WITH_INSTANT_PAYMENT: 'CompletedBookingWithInstantPayment',
  COMPLETE_TENTITIVE_BOOKING_WITH_BANK_TRANSFER: 'CompletedTentativeBookingWithBankTransfer',
  SUBMIT_REQUEST_TO_BOOK: 'SubmittedARequestToBook'
}


//END
