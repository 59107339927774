import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InfinityTable as Table } from 'antd-table-infinity';

const InfiniteTable = ({ columns, dataSource, tableDispatch, props, tableData, scroll }) => {
  const dispatch = useDispatch();
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(1);

  useEffect(() => {
    setPageSize(dataSource?.length || 1)
  },[dataSource])

  const handleFetch = () => {
    const newPage = pageCount + 1;
    setPageCount(newPage);
    dispatch(tableDispatch(pageCount, {...props}));
  };

  const handleTableChange = (pagination, filter, sorter, data) => {
    tableData({
      filteredDataLength: data?.currentDataSource?.length,
      filters: filter
    })
  }

  return (
    <div>
      <Table
       key="tracker" 
       className="table-wrap" 
       onFetch={handleFetch} 
       pageSize={pageSize} 
       columns={columns} 
       dataSource={dataSource}
       onChange={handleTableChange}
       bordered
       scroll ={scroll}
      />
    </div>
  );
};

export default InfiniteTable;
