import React, { useCallback, useEffect, useState } from 'react';
import { TextField } from 'react-md';
import { useSelector } from 'react-redux';
import { showErrorMessage } from '../Common/Common';
import { contentIsNotNull } from '../Common/Validator';
import { AUSTRALIA, DEFAULT_DATA_OBJ, ERROR_NUMBER_EXCEEDED, ERROR_ONLY_NUMBERS } from '../SpaceListingConstants';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { getProfileData, setProfileData } from '../Common/DataController';
import { MEET_CAPITALIZED, WORK_CAPITALIZED } from '../../Common/constants';
import { useParams } from 'react-router-dom';

const SpaceAndNotice = ({ currentBlock }) => {
  const { country } = useParams();
  const [spaceDetails, setSpaceDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const spaceTypes = useSelector((state) => state?.spaceListing?.spaceTypes || []);
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);

  const [selectedActivities, setSelectedActivities] = useState([]);
  const [excludedActivities, setExcludedActivities] = useState([]);

  const profileListingData = JSON.parse(sessionStorage.getItem('profileListingData'));

  useEffect(() => {
    const isAustralia = profileListingData?.space_location?.[0]?.spaceCountry === 'AUSTRALIA';
    const noticePeriodValue = getFieldCurrentValue('noticePeriod');
  
    const isNoticePeriodValid = (noticePeriodValue === 0) || (noticePeriodValue > 0);
  
    setIsNextBtnActive(
      contentIsNotNull(getFieldCurrentValue('category')?.name, 0) &&
      contentIsNotNull(getFieldCurrentValue('activity'), 0) &&
      isNoticePeriodValid &&
      (isAustralia ? getFieldCurrentValue('excludedActivities') : true) &&
      isNoticePeriodValid && 
      (noticePeriodValue < 1000)
    );
  }, [spaceDetails]);

  const handleSpaceDetails = useCallback((name, newValue, onlyNumbers = false) => {
    if (onlyNumbers) {
      newValue = Number(newValue.replace(/^0+/, ''));
    }
    if (onlyNumbers && !/^\d+$/.test(newValue) && newValue !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else if (onlyNumbers && Number(newValue) > 1000) {
      showErrorMessage(ERROR_NUMBER_EXCEEDED);
    } else {
      const tempObj = Object.assign({}, spaceDetails, {
        ...spaceDetails,
        processStarted: true,
        [currentBlock]: spaceDetails?.[currentBlock].map((value) => {
          value[name] = newValue;
          return value;
        }),
      });
      setSpaceDetails(tempObj);
      setProfileData(tempObj);
    }
  }, []);

  const getFieldCurrentValue = (type) => {
    return spaceDetails?.[currentBlock]?.[0]?.[type];
  };

  const selectCategory = (venue) => {
    handleSpaceDetails('category', venue);
    handleSpaceDetails('activity', []);
    emptySeatingAndCommonDetails();
  };

  const handleSeatingAndCommonDetails = (name, newValue) => {
    const tempObj = Object.assign({}, spaceDetails, {
      ...spaceDetails,
      processStarted: true,
      seating_and_common: spaceDetails?.seating_and_common.map((value) => {
        value[name] = newValue;
        return value;
      }),
    });
    setSpaceDetails(tempObj);
    setProfileData(tempObj);
  };

  const selectActivity = (activity) => {
    const currentActivities = getFieldCurrentValue('activity') || [];
    handleSeatingAndCommonDetails('pricingOptions', []);
    if (getFieldCurrentValue('category')?.name === WORK_CAPITALIZED) {
      handleSpaceDetails('activity', [activity]);
    } else {
      if (currentActivities.filter((value) => value?.id === activity?.id)?.length > 0) {
        handleSpaceDetails(
          'activity',
          currentActivities.filter((value) => value?.id !== activity?.id)
        );
      } else {
        currentActivities.push(activity);
        handleSpaceDetails('activity', currentActivities);
      }
    }
  };

  const excludedActivity = (exludedActivity) => {
    const excludedActivities = getFieldCurrentValue('excludedActivities') || [];
    if (getFieldCurrentValue('category')?.name === WORK_CAPITALIZED) {
      handleSpaceDetails('excludedActivities', [exludedActivity]);
    } else {
      if (excludedActivities.filter((value) => value?.id === exludedActivity?.id)?.length > 0) {
        handleSpaceDetails(
          'excludedActivities',
          excludedActivities.filter((value) => value?.id !== exludedActivity?.id)
        );
      } else {
        excludedActivities.push(exludedActivity);
        handleSpaceDetails('excludedActivities', excludedActivities);
      }
    }
  }

  const emptySeatingAndCommonDetails = useCallback(() => {
    const tempObj = Object.assign({}, spaceDetails, {
      ...spaceDetails,
      processStarted: true,
      seating_and_common: spaceDetails?.seating_and_common?.map((value) => {
        value['pricingOptions'] = [];
        return value;
      }),
    });
    setSpaceDetails(tempObj);
    setProfileData(tempObj);
  }, []);

  const handleActivitySelect = (activity) => {
    setSelectedActivities([...selectedActivities, activity]);
    setExcludedActivities(excludedActivities.filter((excluded) => excluded.id !== activity.id));
  };
  
  const handleActivityExclude = (activity) => {
    setSelectedActivities(selectedActivities.filter((selected) => selected.id !== activity.id));
    setExcludedActivities([...excludedActivities, activity]);  
  };

  const handleActivityDeselect = (activity) => {
    setSelectedActivities(selectedActivities.filter((selected) => selected.id !== activity.id));
    setExcludedActivities(excludedActivities.filter((excluded) => excluded.id !== activity.id));
  };

  const handleExcludeDeselect = (activity) => {
    setExcludedActivities(excludedActivities.filter((excluded) => excluded.id !== activity.id));
    setSelectedActivities(selectedActivities.filter((selected) => selected.id !== activity.id));
    
  };

  const isActivitySelected = (activity) => {
    return selectedActivities.some((selected) => selected.id === activity.id);
  };

  const isActivityExcluded = (activity) => {
    return  spaceDetails?.['space_and_notice']?.[0]?.excludedActivities?.filter((value)=>  value?.id === activity?.id)?.length > 0;
  };

  const isButtonDisabled = (activity) => {
    return spaceDetails?.['space_and_notice']?.[0]?.activity?.filter((value)=>  value?.id === activity?.id)?.length > 0;
  };


  return (
    <>
      <div className="left-side-inner-wrapper space-notice-wrapper">
        <div>
          <h1 className="listing-page-title">Activity & notice period details</h1>
          <div className="content-wrapper">
            <p>Who will use your space?</p>
            <div className="btn-wrapper">
              {spaceTypes?.map((venue) => {
                if (venue?.name === WORK_CAPITALIZED) {
                  return (
                    <button
                      className={` border-btn  ${venue.name === getFieldCurrentValue('category')?.name ? 'active' : ''}`}
                      onClick={() => {
                        selectCategory(venue);
                      }}
                    >
                      {' '}
                      Individuals{' '}
                    </button>
                  );
                } else if (venue?.name === MEET_CAPITALIZED) {
                  return (
                    <button
                      className={` border-btn  ${venue.name === getFieldCurrentValue('category')?.name ? 'active' : ''}`}
                      onClick={() => {
                        selectCategory(venue);
                      }}
                    >
                      {' '}
                      Groups / Teams{' '}
                    </button>
                  );
                }
              })}
            </div>
          </div>

          {getFieldCurrentValue('category')?.name && (
            <div className="content-wrapper">
              <p>
                What will your space be used for?{' '}
                {getFieldCurrentValue('category')?.name === 'Meet' ? <span className="gray-text italic">(Multi select)</span> : ''}
                
              </p>
              <div className="btn-wrapper">
                {getFieldCurrentValue('category')?.['venueType']?.map((activity) => (
                  <>
                    <button
                      key={activity?.id}
                      className={` border-btn ${
                        getFieldCurrentValue('activity')?.filter((value) => value?.id === activity?.id)?.length > 0 ? 'active' : ''
                      }`}
                      onClick={() => {
                        selectActivity(activity);
                        isActivitySelected(activity) ? handleActivityDeselect(activity) : handleActivitySelect(activity);
                      }}
                      disabled={isActivityExcluded(activity)}
                    >
                      {activity?.name}
                      
                    </button>
                  </>
                ))}
              </div>
            </div>
          )}

          {getFieldCurrentValue('category')?.name === 'Meet' && profileListingData?.space_location?.[0]?.spaceCountry == AUSTRALIA ? (            
            <>
              { getFieldCurrentValue('category')?.name && (
                <div className="content-wrapper">
                  <p>
                    What activities are absolutely excluded?{' '}
                    {getFieldCurrentValue('category')?.name === 'Meet' ? <span className="gray-text italic">(Multi select)</span> : ''}
                    
                  </p>
                  <div className="btn-wrapper">
                    {getFieldCurrentValue('category')?.['venueType']?.map((activity) => (
                      <>
                        <button
                          key={activity?.id}
                          className={`border-btn ${getFieldCurrentValue('excludedActivities')?.filter((value) => value?.id === activity?.id)?.length > 0? 'active' : ''}`}
                          onClick={() => {
                            excludedActivity(activity);
                            isActivityExcluded(activity) ? handleExcludeDeselect(activity) : handleActivityExclude(activity);
                          }}
                          disabled={isButtonDisabled(activity)}
                        >
                          {activity?.name}
                      
                        </button>
                      </>
                    ))}
                  </div>
                </div>
              )}
            </>
          ):("")}

          {getFieldCurrentValue('activity')?.length > 0 && (
            <>
              <hr className="divider" />
              <p>How many hours of advanced notice do you require for a reservation?</p>
              <div className="common-max-width-sm">
                <TextField
                  id="noticePeriod"
                  label="Notice Period"
                  lineDirection="center"
                  errorText="This field is required."
                  type={'number'}
                  onChange={(value) => handleSpaceDetails('noticePeriod', value, true)}
                  value={getFieldCurrentValue('noticePeriod')}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive} />
    </>
  );
};

export default SpaceAndNotice;
