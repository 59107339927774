import { checkMonthlyPaaymentOption } from './../../helper/dashboardSpaceDetails';
import React, { useState, useEffect } from 'react';
import { Collapse, Popover } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import arrow from '../../assets/images/common/black-arrow.svg';
import ellipsis from '../../assets/images/host/ellipsis.svg';
import person from '../../assets/images/common/person.svg';

import { fetchSpaceDetails } from './HostDashboardActions';
import { IMAGES_BASE_URL } from '../../settings';
import { getValidColoreForSpaceStatus } from '../../helper/StatusColor'
import HostFooter from './HostFooter';
import useWindowDimensions from '../Common/Hooks/useWindowDimensions';
import ProgressMessage from '../Common/ProgressMessage';
import HelpAndSupport from '../Static-pages/HelpAndSupport/HelpAndSupport';
import {SPACE_STATUS} from "./HostDashboardActionTypes";
import { useParams } from 'react-router-dom';
import { COUNTRY_CODES} from '../Common/constants';
const { Panel } = Collapse;

const text = `
Seating details
`;

const SpaceDetails = ({handleBackClicked}) => {
    const dispatch = useDispatch();
    const dimensions = useWindowDimensions();

    const spaceDetails = useSelector(state => state?.hostDashboard?.data?.spaceDetails || []);
    const mobileView = dimensions.width < 992;
    const [visibleComingSoon, setVisibleComingSoon] = useState(false);
    const [visibleModel, setHelpAndSupportVisible] = useState(false);
    const { country, status, spaceId } = useParams();
    const spaceLocation = spaceDetails?.location?.country || COUNTRY_CODES.SINGAPORE.country_shortCode;

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchSpaceDetails(spaceId));
    }, [spaceId]);

    const handleManageCalenderOnClick = () => {
        const URLtoPush = `/${country}/dashboard/calendar?spaceId=${spaceId}&status=${status}`
        dispatch(push(URLtoPush));
    }
    const handleManageVisitsClick = () => {
        const URLtoPush = `/${country}/dashboard/visits?spaceId=${spaceId}&status=${status}`
        dispatch(push(URLtoPush));
    }

    const handleReservationOnClick = () => {
        const URLtoPush = `/comingsoon`
        dispatch(push(URLtoPush));
    }

    const handleBack = () => {
        handleBackClicked(true);
        const URLtoPush = `/${country}/dashboard/myspaces/${status}`
        dispatch(push(URLtoPush));
    };

    const redirect = (path) => {
        dispatch(push(path));
      };
    
    const content = (
        <div className="support-popover">
            <span className="list-items" onClick={()=>setHelpAndSupportVisible(true)}>
            <img src={person} alt="arrow" className="arrow-img icon" />Help
            </span>
        </div>
    );

    return (
        <>
            <div className="my-space-details host-dashboard">
                <div className="header">
                    <div className="container custom-container flex-wrap">
                        <div className="left">
                            <img src={arrow} alt="arrow" className="arrow-img" onClick={() => handleBack()}/>
                            {/* <div className="favourites"><img src={star} alt="img" className="icon" /><span className="number">4.86 </span><span className="gray-text">(25)</span></div> */}
                        </div>
                        <div className="right">
                            <Popover placement="bottomRight" content={content} trigger="click" overlayClassName="space-popover">
                                <img src={ellipsis} alt="img" className="ellipsis-img" />
                            </Popover>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="container custom-container">
                        <div className="header-content">
                            <span className="title">Ref #{spaceDetails?.id?.toString().substring(0,7) || "No Reference"} • {spaceDetails?.events?.length ? spaceDetails?.events[0]?.eventType.name.toUpperCase() : 'ALL'}</span>
                            <span className={`status ${getValidColoreForSpaceStatus(spaceDetails.spaceStatus)}`}>{spaceDetails.spaceStatus}</span>
                            <span className="sub-title">{spaceDetails?.spaceName}</span>
                            <span className="title">{spaceDetails?.location?.city.toUpperCase()} • {spaceDetails?.location?.country == 'LK' ? 'SRI LANKA' : 'SINGAPORE'}</span>
                        </div>
                        <div className="content">
                            {checkMonthlyPaaymentOption(spaceDetails.events?.[0]?.chargings || []) && <>
                                <button className="btn btn-teal btn-manage" disabled={SPACE_STATUS.ACTIVE !== spaceDetails.spaceStatus || !spaceDetails?.instanceBookingAvailability} onClick={()=>{handleManageCalenderOnClick()}}>MANAGE CALENDAR</button>
                                <button className="btn btn-teal btn-manage" disabled={SPACE_STATUS.ACTIVE !== spaceDetails.spaceStatus} onClick={() => setVisibleComingSoon(true)}>MANAGE RESERVATION</button>
                                {spaceLocation === COUNTRY_CODES.SRI_LANKA.country_shortCode && <button className="btn btn-teal btn-manage" disabled={SPACE_STATUS.ACTIVE !== spaceDetails.spaceStatus} onClick={() => {handleManageVisitsClick()}}>MANAGE VISITS</button>}
                            </>}
                            <div className="img-wrap">
                                {spaceDetails?.images?.map(img =>
                                    <span className="img" style={{ backgroundImage: `url(${IMAGES_BASE_URL + img.url})` }}></span>
                                )}
                            </div>
                            {/* <Collapse className="collapse-content">
                                <Panel header="Basic details" key="1" className="panel-content" disabled={true}>
                                    <p>{text}</p>
                                </Panel>
                            </Collapse>

                            <Collapse className="collapse-content">
                                <Panel header="Seating details" key="1" className="panel-content" disabled>
                                    <p>{text}</p>
                                </Panel>
                            </Collapse>
                            <Collapse className="collapse-content">
                                <Panel header="Common details" key="1" className="panel-content" disabled>
                                    <p>{text}</p>
                                </Panel>
                            </Collapse>
                            <Collapse className="collapse-content">
                                <Panel header="PricingOptions & opening hours" key="1" className="panel-content" disabled>
                                    <p>{text}</p>
                                </Panel>
                            </Collapse> */}
                        </div>
                    </div>
                </div>

            </div>
            {mobileView && <HostFooter/>}
            {visibleComingSoon && (<ProgressMessage handleComingSoonModalClose={() => setVisibleComingSoon(false)}/>)}
            {visibleModel && (<HelpAndSupport visibleHelpAndSupport={visibleModel} closeHelpAndSupport={()=>setHelpAndSupportVisible(false)}/>)}
        </>
    );
}

export default SpaceDetails;
