import * as actions from './BookingActionTypes';

export const BookingReducer = (
    state = {
        fetching: false,
        error: { status: false, message: "" },
        data: [],
        guestDetails : {
            guestCount : 1
        },
        bookingFlow : {
            isBookingFlow : false
        },
        confirmedBooking : {
            fetching: false,
            error: { status: false, message: "" },
            data: []
        }
    }, action) => {
    switch (action.type) {

        case actions.FETCH_BOOKING_DETAILS:
            return Object.assign({}, state, { fetching: true }, { error: { status: false, message: "" } });

        case actions.FETCH_BOOKING_DETAILS_SUCCESS:
            return {
                ...state,
                fetching: false,
                data: action.payload,
                error: { status: false, message: "" }
            };

        case actions.FETCH_BOOKING_DETAILS_FAILS:
            return Object.assign({}, { data: [] }, { fetching: false }, { error: { status: true, message: "" } });

        case actions.FETCH_SAVINGS:
            return Object.assign({}, state, { fetching: true });

        case actions.FETCH_SAVINGS_SUCCESS:
            return {
                ...state,
                fetching: false,
                savings: action.payload
            };

        case actions.FETCH_SAVINGS_FAILS:
            return Object.assign({}, { savings: [] }, { fetching: false }, { error: { status: true, message: "" } });

        case actions.FETCH_TAX:
            return Object.assign({}, state, { fetching: true }, { error: { status: false, message: "" } });

        case actions.FETCH_TAX_SUCCESS:
            return {
                ...state,
                fetching: false,
                tax: action.payload,
                error: { status: false, message: "" }
            };

        case actions.FETCH_TAX_FAILS:
            return Object.assign({}, { tax: [] }, { fetching: false }, { error: { status: true, message: "" } });

        case actions.POST_BOOKING_DATA:
            return {
                ...state,
                confirmedBooking : {
                    ...state.confirmedBooking,
                    fetching: true,
                    bookingSuccess: false,
                    data: [],
                    error: { status: false, message: "" }
                }
            };
        case actions.POST_BOOKING_DATA_SUCCESS:
            return {
                ...state,
                confirmedBooking : {
                    ...state.confirmedBooking,
                    fetching: false,
                    bookingSuccess: true,
                    data: action.payload,
                    error: { status: false, message: "" }
                }
            };

        case actions.POST_BOOKING_DATA_FAIL:
            return {
                ...state,
                error: { status: true, message: action?.payload?.response?.data, data: action?.payload },
                confirmedBooking : {
                    ...state.confirmedBooking,
                    fetching: false,
                    bookingSuccess: false,
                    data: [],
                    error: { status: true, message: action?.payload?.response?.data, data: action?.payload }
                }
            };

        case actions.POST_BOOKING_REQUEST_BOOKING_DATA:
            return Object.assign({}, state, { fetching: true }, { error: { status: false, message: "" } });

        case actions.POST_BOOKING_DATA_REQUEST_BOOKING_SUCCESS:
            return {
                ...state,
                fetching: false,
                requestToBookingSuccess: true,
                confirmedBookingRequest: action.payload,
                error: { status: false, message: "" }
            };

        case actions.POST_BOOKING_DATA_REQUEST_BOOKING_FAIL:
            return Object.assign({}, state, { error: { status: true, message: action?.payload?.response?.data } });

        case actions.POST_BOOKING_DATA_REQUEST_BOOKING_CLEAR:
            return {
                ...state,
                fetching: false,
                requestToBookingSuccess: false,
                error: { status: false, message: "" }
            };

        case actions.CLEAR_BOOKING_DATA_FAIL:
            return Object.assign({}, state, { error: {} });

        case actions.TAX_SAVINGS:
            return {
                ...state,
                taxAndSavings:action.payload
            };

        case actions.BOOKING_PAYLOAD:
            return {
                ...state,
                bookingPayload:action.payload
            };

        case actions.FETCH_SPACE_AVAILABILITY:
            return Object.assign({}, state, { fetching: true });

        case actions.FETCH_SPACE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                fetching: true,
                spaceAvailability : action.payload
            };

        case actions.FETCH_SPACE_AVAILABILITY_FAILS:
            return Object.assign(
                {},
                { spaceAvailability: [] },
                { fetching: false },
                { error: { status: true, message: "" }
            });

        case actions.SET_GUEST_COUNT:
            return {
                ...state,
                guestDetails: {
                    ...state.guestDetails,
                    guestCount : action.payload
                }
            };
        case actions.PACKAGE_SUBMIT:
            return {
                ...state,
                package: {
                    ...state.package,
                    data : updateObjectInArray(state?.package?.data || [] , action.payload, 'code')
                }
            };
        case actions.PACKAGE_DELETE:
            return {
                ...state,
                package: {
                    ...state.package,
                    data : state.package.data.filter(packageDetails => packageDetails.code !== action.payload)
                }
            };
        case actions.PACKAGES_DELETE:
            return {
                ...state,
                package: {
                    ...state.package,
                    data : []
                }
            };
        case actions.BOOKING_FLOW:
            return {
                ...state,
                bookingFlow: {
                    ...state.bookingFlow,
                    isBookingFlow : action.payload
                }
            };
        case actions.FETCH_PROMO_CODE: 
            return {
                ...state,
                promoCodeDetails: {
                    ...state.promoCodeDetails,
                    fetching: true,
                    error: { status: false, message: "" }
                }
            };
        case actions.FETCH_PROMO_CODE_SUCCESS: 
            return {
                ...state,
                promoCodeDetails: {
                    ...state.promoCodeDetails,
                    fetching: false,
                    error: { status: false, message: "" },
                    data: action.payload
                }
        };
        case actions.FETCH_PROMO_CODE_FAILS: 
            return {
                ...state,
                promoCodeDetails: {
                    ...state.promoCodeDetails,
                    fetching: false,
                    error: { status: true, message: action.payload },
                }
        };
        case actions.CLEAR_PROMO_CODE: 
            return {
                ...state,
                promoCodeDetails: {
                    ...state.promoCodeDetails,
                    fetching: false,
                    error: { status: false, message: "" },
                    data: {}
                }
        };
        case actions.TENTATIVE_BOOKING_SUCCESS:
            return {
                ...state,
                tentativeBooking: {
                    bookingSuccess : true
                }
            };
        case actions.TENTATIVE_BOOKING_FAILED:
            return {
                ...state,
                tentativeBooking: {
                    bookingSuccess : false
                }
            };
        case actions.TENTATIVE_BOOKING_CONFIRM_SUCCESS:
            return {
                ...state,
                tentativeBooking: {
                    bookingConfirmSuccess : true
                }
            };
        case actions.TENTATIVE_BOOKING_CONFIRM_RESET:
            return {
                ...state,
                tentativeBooking: {
                    bookingConfirmSuccess : action.payload
                }
            };
        case actions.TENTATIVE_BOOKING_CONFIRM_FAILED:
            return {
                ...state,
                tentativeBooking: {
                    bookingConfirmSuccess : false
                }
            };

        default:
            return state;
    }


    function updateObjectInArray(array, payload, key) {
        if(Boolean(array.length)){
            if(Boolean(array.filter(value => value[key] === payload[key]).length)){
                return array.map((item) => {
                    if (item[key] !== payload[key]) {
                      return item
                    }

                    return {
                      ...item,
                      ...payload
                    }
                })
            }else{
                return [...array, payload]
            }

        }else{
            return [payload]
        }
      }
};
