export const FETCH_PAYMENT_REPORT_DATA = 'FETCH_PAYMENT_REPORT_DATA'
export const FETCH_PAYMENT_REPORT_DATA_SUCCESS = 'FETCH_PAYMENT_REPORT_DATA_SUCCESS'
export const FETCH_PAYMENT_REPORT_DATA_FAILS = 'FETCH_PAYMENT_REPORT_DATA_FAILS'

export const FETCH_BOOKING_REPORT_DATA = 'FETCH_PAYMENT_BOOKING_DATA'
export const FETCH_BOOKING_REPORT_DATA_SUCCESS = 'FETCH_BOOKING_REPORT_DATA_SUCCESS'
export const FETCH_BOOKING_REPORT_DATA_FAILS = 'FETCH_BOOKING_REPORT_DATA_FAILS'

export const FETCH_REVENUE_REPORT_DATA = 'FETCH_REVENUE_REPORT_DATA'
export const FETCH_REVENUE_REPORT_DATA_SUCCESS = 'FETCH_REVENUE_REPORT_DATA_SUCCESS'
export const FETCH_REVENUE_REPORT_DATA_FAILS = 'FETCH_REVENUE_REPORT_DATA_FAILS'

export const FETCH_PAYMENT_REFUND_REPORT_DATA = 'FETCH_PAYMENT_REFUND_REPORT_DATA'
export const FETCH_PAYMENT_REFUND_REPORT_DATA_SUCCESS = 'FETCH_PAYMENT_REFUND_REPORT_DATA_SUCCESS'
export const FETCH_PAYMENT_REFUND_REPORT_DATA_FAILS = 'FETCH_PAYMENT_REFUND_REPORT_DATA_FAILS'

export const FETCH_SPACE_REPORT_DATA = 'FETCH_SPACE_REPORT_DATA'
export const FETCH_SPACE_REPORT_DATA_SUCCESS = 'FETCH_SPACE_REPORT_DATA_SUCCESS'
export const FETCH_SPACE_REPORT_DATA_FAILS = 'FETCH_SPACE_REPORT_DATA_FAILS'

export const FETCH_USER_REPORT_DATA = 'FETCH_USER_REPORT_DATA'
export const FETCH_USER_REPORT_DATA_SUCCESS = 'FETCH_USER_REPORT_DATA_SUCCESS'
export const FETCH_USER_REPORT_DATA_FAILS = 'FETCH_USER_REPORT_DATA_FAILS'

export const FETCH_SUMMARY_REPORT_DATA = 'FETCH_SUMMARY_REPORT_DATA'
export const FETCH_SUMMARY_REPORT_DATA_SUCCESS = 'FETCH_SUMMARY_REPORT_DATA_SUCCESS'
export const FETCH_SUMMARY_REPORT_DATA_FAILS = 'FETCH_SUMMARY_REPORT_DATA_FAILS'

export const DOWNLOAD_CSV = 'DOWNLOAD_CSV'
export const DOWNLOAD_CSV_SUCCESS = 'DOWNLOAD_CSV_SUCCESS'
export const DOWNLOAD_CSV_FAILS = 'DOWNLOAD_CSV_FAILS'

export const FETCH_REPORT_STATUS = 'FETCH_REPORT_STATUS'
export const FETCH_REPORT_STATUS_SUCCESS = 'FETCH_REPORT_STATUS_SUCCESS'
export const FETCH_REPORT_STATUS_FAILS = 'FETCH_REPORT_STATUS_FAILS'

export const FETCH_AVAILABLE_REPORTS = 'FETCH_AVAILABLE_REPORTS'
export const FETCH_AVAILABLE_REPORTS_SUCCESS = 'FETCH_AVAILABLE_REPORTS_SUCCESS'
export const FETCH_AVAILABLE_REPORTS_FAILS = 'FETCH_AVAILABLE_REPORTS_FAILS'

export const CLEAR_AVAILABLE_REPORTS = 'CLEAR_AVAILABLE_REPORTS'
export const CLEAR_COMPLETED_TRACKER_STATUS = 'CLEAR_COMPLETED_TRACKER_STATUS'

  