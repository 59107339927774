import React,{useState} from 'react';
import CommonTextField from '../../Common/Textfield/CommonTextField';
import HelpSupport from '../../../assets/images/common/help-support.svg';
import HeaderImg from '../../../assets/images/help-support/help-backgroundImg.svg';
import message from '../../../assets/images/common/message-white.svg';
import phone from '../../../assets/images/common/phone-white.svg';
import SingaporeRedImg from '../../../assets/images/help-support/singapore-white.svg';
import SrilankaBlueImg from '../../../assets/images/help-support/srilanka-white.svg';
import srilanka from '../../../assets/images/home/SLFlagColored.svg';
import singapore from '../../../assets/images/home/SingaporeColored.svg';
import adrien from '../../../assets/images/about-us/adrien_new.jpg';
import marietta from '../../../assets/images/about-us/marietta.jpg';
import closeIcon from "../../../assets/images/common/close.svg";
import TextArea from 'antd/lib/input/TextArea';
import PropTypes from 'prop-types';
import { Modal} from 'antd';
import { useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';

const HelpAndSupport = ({closeHelpAndSupport,closeRoute}) => (
  // return (
  // <>
    <div>
      <section className="section section-help-support">
        <div className="bg-img-section" style={{ backgroundImage: `url(${HeaderImg})` }}>
          <div className="close-btn">
            <img src={closeIcon} alt="close" className="img" onClick={closeHelpAndSupport || closeRoute}/>
          </div>
          <div className="container custom-container header-block-inner-wrapper">
            <div className="main-description">Get in touch with <br /> a member of our team</div>
          </div>
        </div>
        {/* <div className="container custom-container form-outer-wrapper">
          <h2 className="title">Help & support</h2>
          <div className="img-wrapper">
            <img className="img" alt="help & support" src={HelpSupport} />
          </div>
          <div className="form-wrapper">
            <h2 className="title">Have a question?</h2>
            <span className="small-text">Leave us a message and one of our space experts will be in touch</span>
            <div className="input-wrapper">
              <CommonTextField placeholder="Name" name={'name'} />
              <CommonTextField placeholder="Email" type="email" name={'email'} />
            </div>
            <div className="input-wrapper half-width">
              <CommonTextField placeholder="Contact number" type="email" name={'number'} />
            </div>
            <CommonTextField placeholder="Title" type="email" name={'title'} />
            <TextArea placeholder="Message" className="msg-cls" />
            <button className="btn-yellow">submit</button>
          </div>
        </div> */}
      </section>
      <footer className="help-support-footer-section">
        <div className="blue-background">
          <div className="container custom-container address-wrapper">
            <div className="img-wrapper" style={{ backgroundImage: `url(${SingaporeRedImg})` }}>
              <div className="country-wrap">
                <div className="img-inner-wrap">
                  <img src={adrien} alt="adrien" className="person-img" />
                  <img src={singapore} alt="singapore" className="img" />
                </div>
                <span className="person-name">Adrien</span>
                {/* <div className="detail-icon-block">
                  <img className="img" src={location} alt="icon" />
                  <span className="detail">
                    {'38 Ang Mo Kio Industrial Park 2 #04-52,'} <br />
                    {'Singapore 569511'}
                  </span>
                </div> */}
                <div className="detail-icon-block">
                  <a className="detail" href="mailto:hellosg@millionspaces.com">
                    hellosg@millionspaces.com
                  </a>
                </div>
                <div className="detail-icon-block">
                  <a className="detail" href="tel:+6587226658">
                    +65 8722 6658
                  </a>
                </div>
              </div>
            </div>
            <div className="img-wrapper" style={{ backgroundImage: `url(${SrilankaBlueImg})` }}>
              <div className="country-wrap">
                <div className="img-inner-wrap">
                  <img src={marietta} alt="marietta" className="person-img" />
                  <img src={srilanka} alt="srilanka" className="img" />
                </div>
                <span className="person-name">Marietta</span>
                {/* <div className="detail-icon-block">
                <img className="img" src={location} alt="icon" />
                <span className="detail">{'61, Janadhipathi Mw, Col 1, Sri Lanka'}</span>
              </div> */}
                <div className="detail-icon-block">
                  <a className="detail" href="mailto:hello@millionspaces.com">
                    hello@millionspaces.com
                  </a>
                </div>
                <div className="detail-icon-block">
                  <a className="detail" href="tel:+94117811811">
                    +94 117 811 811
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  // </>
  // );
);

const HelpAndSupportModel = ({closeHelpAndSupport}) => {
  const locationPath = useSelector((state) => state?.router?.location?.pathname || '');
  const [visibleModel, setHelpAndSupportVisible] = useState(false);
  const dispatch = useDispatch();
  const { country } = useParams();
  useEffect(()=>{
    if(locationPath?.includes('/help')&& visibleModel){
      dispatch(push(`/${country}`));
    }
    return ()=>{
      setHelpAndSupportVisible(false);
    }
  },[visibleModel])

  return (
      <>
          <div className="help-dialog" >
              <HelpAndSupport closeHelpAndSupport={closeHelpAndSupport} closeRoute={()=>{setHelpAndSupportVisible(true)}}/>
          </div>
      </>
  )
}

HelpAndSupportModel.propTypes = {
  visibleHelpAndSupport: PropTypes.bool,
  closeHelpAndSupport: PropTypes.bool,
};

export default HelpAndSupportModel;
