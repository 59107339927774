import React, { useEffect, useState } from 'react';
import { getProfileData } from './DataController';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { submitMeetSpaceDetails, submitWorkSpaceDetails, updateToHost } from '../ListingAction';
import {
  ADDONS,
  AMENITIES,
  AUTHORITIES,
  BACK_BTN,
  BACK_TO_HOME,
  BLOCK_OPTION,
  COMMISSION,
  DAILY_OPTION,
  HOUR_OPTION,
  HOURLY_OPTION,
  LOGIN_DETAILS,
  MEETING_SPACE_CODE,
  PAYOUT_DETAILS,
  PRICING_OPTIONS,
  RULES,
  SAVINGS,
  SEATING_AND_COMMON,
  SEATING_ARRANGEMENT,
  SPACE_AND_NOTICE,
  SPACE_DETAILS,
  SPACE_LOCATION,
  TAX_INFORMATION,
  UPLOADING_IMAGES,
  USER_ROLE,
  AUSTRALIA,
} from '../SpaceListingConstants';
import { HOURLY, WORK_CAPITALIZED } from '../../Common/constants';
import arrowIcon from '../../../assets/images/common/black-arrow.svg';
import { useHistory, useParams } from 'react-router-dom';
import decode from 'jwt-decode';
import { changeLoginModalVisibility, getUserDetailByToken, updateReduxWithAuthDetails } from '../../../redux/auth/authAction';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import { showErrorMessage } from './Common';
import { CLEAR_CONVERT_TO_HOST, CLEAR_DATA_SUBMIT, SET_TOKEN_UPDATE_STATUS } from '../ListingActionTypes';
import { DialogContainer, TextField } from 'react-md';

const FlowHandlingButtons = ({
  backBtnClass = '',
  nextBtnClass = '',
  isActive = true,
  frontButton = true,
  backButton = true,
  backButtonText = BACK_BTN,
  nextButtonText = 'NEXT',
  isSubmit = false,
  errorMessage = '',
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const submitResponse = useSelector((state) => state?.spaceListing?.submitResponse || []);
  const authDetails = useSelector((state) => state?.auth?.loginDetails?.data?.token || null);
  const newHostAuthToken = useSelector((state) => state?.spaceListing?.userToHost || null);
  const [localNextBtnDisable, setLocalNextBtnDisable] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['authDetails']);
  const country = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(' ', '-') || null);
  const profileListingData = JSON.parse(sessionStorage.getItem('profileListingData'));

  useEffect(() => {
    if (submitResponse?.status === 1 && window.location.pathname.includes(RULES)) {
      dispatch(push(getProfileData()?.routeList?.[getProfileData()?.routeList?.length - 1]));
    } else if (submitResponse?.response) {
      showErrorMessage('Error occurred');
      dispatch({ type: CLEAR_DATA_SUBMIT, payload: 'error' });
    }
  }, [submitResponse]);

  const getBlockValue = (type) => {
    return getProfileData()?.[type]?.[0];
  };

  const getTaxDetailsArray = () => {
    return getBlockValue(TAX_INFORMATION)?.applicableTaxes?.map((value) => {
      return {
        id: getBlockValue(TAX_INFORMATION)?.[getBlockValue(TAX_INFORMATION)?.currency]?.find((selectedTax) => {
          return selectedTax?.taxName === value;
        })?.id,
      };
    });
  };

  const getImageList = () => {
    return getBlockValue(UPLOADING_IMAGES)?.uploadedImages?.map((imageElement) => {
      return {
        url: imageElement?.public_id,
        publicId: imageElement?.public_id,
        etag: imageElement?.etag,
        signature: imageElement?.signature,
        isPrimary: imageElement?.asset_id === getBlockValue(UPLOADING_IMAGES)?.selectedImage,
      };
    });
  };

  const getKeepInMind = () => {
    return getBlockValue(RULES)?.data?.map((value) => {
      return {
        rule: value?.id,
        isDisabled: value?.selectedStatus,
      };
    });
  };

  const getAmenities = () => {
    return getBlockValue(AMENITIES)
      ?.data?.map((value) => {
        if (value?.selectedStatus) {
          return {
            id: value?.id,
          };
        }
        return null;
      })
      ?.filter((value) => value !== null);
  };

  const getCancellationPolicy = () => {
    return getBlockValue(SEATING_AND_COMMON)?.cancellationPolicyRaw?.filter((value) => {
      return getBlockValue(SEATING_AND_COMMON)?.cancellationPolicy === value?.name;
    })?.[0]?.id;
  };

  const getChargingObject = (unit) => {
    return getBlockValue(SEATING_AND_COMMON)?.pricingOptionRaw?.filter((value) => value?.unit === unit);
  };

  const getPricingOptionsDetails = (option, subOption) => {
    return getBlockValue(PRICING_OPTIONS)?.[option]?.[subOption];
  };

  const getPackageDetails = (option) => {
    if (getPricingOptionsDetails(option, 'packageDetails')?.packageStatus) {
      return getPricingOptionsDetails(option, 'packageDetails')?.packages?.map((value) => {
        return {
          type: {
            id: value?.packageTypeId,
          },
          name: value?.packageName,
          description: value?.description,
          quantity: value?.maxGuestQty,
          price: {
            amount: value?.unitPrice,
          },
        };
      });
    } else {
      return [];
    }
  };

  const getDailyWeeklyPricingOptionObj = (option) => {
    if (getPricingOptionsDetails(option, 'pricingDetails')?.twentySevenAccess) {
      return [
        {
          name: 'Daily',
          price: {
            amount: getPricingOptionsDetails(option, 'pricingDetails')?.price,
            currency: getBlockValue(TAX_INFORMATION)?.currency,
            currencySymbol: getBlockValue(TAX_INFORMATION)?.currency,
          },
        },
      ];
    } else {
      if (option === 'monthly') {
        return [
          {
            name: 'Daily',
            startTime: getPricingOptionsDetails(option, 'availabilityDetails')?.fullTimeAccess
              ? null
              : getPricingOptionsDetails(option, 'availabilityDetails')?.startTime,
            endTime: getPricingOptionsDetails(option, 'availabilityDetails')?.fullTimeAccess
              ? null
              : getPricingOptionsDetails(option, 'availabilityDetails')?.endTime,
            price: {
              amount: getPricingOptionsDetails(option, 'pricingDetails')?.price,
              currency: getBlockValue(TAX_INFORMATION)?.currency,
              currencySymbol: getBlockValue(TAX_INFORMATION)?.currency,
            },
          },
        ];
      } else {
        return [].concat.apply(
          [],
          getPricingOptionsDetails(option, 'pricingDetails')?.days?.map((value) => {
            return value?.slots?.map((slotValue) => {
              return {
                startTime: slotValue?.startTime,
                endTime: slotValue?.endTime,
                name: value?.type,
                pricePerHour: getPricingOptionsDetails(option, 'pricingDetails')?.price,
                price: {
                  amount: getPricingOptionsDetails(option, 'pricingDetails')?.price,
                  currency: getBlockValue(TAX_INFORMATION)?.currency,
                  currencySymbol: getBlockValue(TAX_INFORMATION)?.currency,
                },
              };
            });
          })
        );
      }
    }
  };

  const singleChargingTypeDetails = (option) => {
    return {
      chargingType: {
        id: getChargingObject(option)?.[0]?.id,
      },
      chargingTypeDesc: {
        name: getChargingObject(option)?.[0]?.name,
      },
      refundableDepositAvailability: getPricingOptionsDetails('monthly', 'pricingDetails')?.refundableDepositAvailable,
      refundableDeposit: {
        months: getPricingOptionsDetails('monthly', 'pricingDetails')?.refundableDepositDuration || 0,
      },
      access24by7:
        getPricingOptionsDetails(option === 'day' ? 'daily' : option === 'month' ? 'monthly' : 'weekly', 'pricingDetails')?.twentySevenAccess ||
        false,
      packages: getPackageDetails(
        getBlockValue(SPACE_AND_NOTICE)?.category?.name === WORK_CAPITALIZED
          ? option === 'day'
            ? 'daily'
            : option === 'month'
            ? 'monthly'
            : 'weekly'
          : option === 'block'
          ? BLOCK_OPTION
          : DAILY_OPTION
      ),
      pricingOptions: getDailyWeeklyPricingOptionObj(
        getBlockValue(SPACE_AND_NOTICE)?.category?.name === WORK_CAPITALIZED
          ? option === 'day'
            ? 'daily'
            : option === 'month'
            ? 'monthly'
            : 'weekly'
          : option === 'block'
          ? BLOCK_OPTION
          : DAILY_OPTION
      ),
      noticePeriod: getBlockValue(SPACE_AND_NOTICE)?.noticePeriod,
    };
  };

  const getHourlyDiscountsSavings = () => {
    return getPricingOptionsDetails(HOURLY_OPTION, 'pricingDetails')?.discounts?.map((selectedDiscountValues) => {
      return selectedDiscountValues?.slots?.map((slotValues) => {
        return {
          id: getBlockValue(SAVINGS)?.data?.filter((value) => value?.name === selectedDiscountValues?.type)?.[0]?.id,
          term: Number(moment(slotValues?.applicableFrom, 'HH:mm').hours()) || 0,
          amount: slotValues?.discountPercentage,
        };
      });
    });
  };

  const getHourlyChargingTypeDetails = (option) => {
    if (getProfileData()?.space_and_notice?.[0]?.activity?.length === 1) {
      return {
        chargingType: {
          id: getChargingObject(option)?.[0]?.id,
        },
        packages: getPackageDetails(
          getBlockValue(SPACE_AND_NOTICE)?.category?.name === WORK_CAPITALIZED
            ? option === 'day'
              ? 'daily'
              : 'weekly'
            : option === 'block'
            ? BLOCK_OPTION
            : option === 'hour'
            ? HOURLY_OPTION
            : DAILY_OPTION
        ),
        minimumBookingPeriodAvailability: true,
        acceptedHalfHourBooking: getPricingOptionsDetails(HOURLY_OPTION, 'pricingDetails')?.halfHourBookings || false,
        minimumBookingPeriod: Number(moment(getPricingOptionsDetails(HOURLY_OPTION, 'pricingDetails')?.minimumBookingPeriod, 'HH:mm').hours()) || 0,
        discounts: [].concat.apply([], getHourlyDiscountsSavings()),
        pricingOptions: getDailyWeeklyPricingOptionObj(HOURLY_OPTION),
        noticePeriod: getBlockValue(SPACE_AND_NOTICE)?.noticePeriod,
      };
    } else {
      return {
        chargingType: {
          id: getChargingObject(option)?.[0]?.id,
        },
        minimumBookingPeriodAvailability: true,
        acceptedHalfHourBooking: getPricingOptionsDetails(HOURLY_OPTION, 'pricingDetails')?.halfHourBookings || false,
        minimumBookingPeriod: Number(moment(getPricingOptionsDetails(HOURLY_OPTION, 'pricingDetails')?.minimumBookingPeriod, 'HH:mm').hours()) || 0,
        discounts: [].concat.apply([], getHourlyDiscountsSavings()),
        pricingOptions: getDailyWeeklyPricingOptionObj(HOURLY_OPTION),
        noticePeriod: getBlockValue(SPACE_AND_NOTICE)?.noticePeriod,
      };
    }
  };

  const getChargingTypeArray = () => {
    return getBlockValue(SEATING_AND_COMMON)?.pricingOptions?.map((value) =>
      value === HOUR_OPTION ? getHourlyChargingTypeDetails(value) : singleChargingTypeDetails(value)
    );
  };

  const getAddonDetails = () => {
    return getBlockValue(ADDONS)
      ?.data?.map((value) => {
        if (value?.selectedStatus) {
          return {
            addOn: {
              id: value?.id,
            },
            charge: {
              price: {
                amount: value?.price,
                currency: getBlockValue(TAX_INFORMATION)?.currency,
                currencySymbol: getBlockValue(TAX_INFORMATION)?.currency,
              },
            },
            description: value?.description,
          };
        }
      })
      ?.filter((value) => value !== undefined);
  };

  const getPricingOptionIdList = () => {
    return getBlockValue(SEATING_AND_COMMON)?.pricingOptions?.map((value) => {
      getChargingObject(value);
    });
  };

  const getSeatingArrangementInMeet = () => {
    return getBlockValue(SEATING_ARRANGEMENT)
      ?.data?.map((value) => {
        if (getBlockValue(SEATING_ARRANGEMENT)?.selectedTypes?.includes(value?.id)) {
          return {
            seatArrangement: {
              id: value?.id,
            },
            min: 1,
            max: Number(value?.capacity),
          };
        }
      })
      ?.filter((value) => value !== undefined);
  };

  const getEventTypes = () => {
    return getBlockValue(SPACE_AND_NOTICE)?.activity?.map((value) => {
      return {
        id: value?.id,
      };
    });
  };

  const getExcludedEventTypes = () => {
    return getBlockValue(SPACE_AND_NOTICE)?.excludedActivities?.map((value) => {
      return {
        id: value?.id,
      };
    });
  };

  const payoutDetailsAus = {
    nameOfAccount: getBlockValue(PAYOUT_DETAILS)?.name,
    bank: getBlockValue(PAYOUT_DETAILS)?.bank,
    bsb: getBlockValue(PAYOUT_DETAILS)?.bsb,
    accountNumber: getBlockValue(PAYOUT_DETAILS)?.accountNumber,
  };

  const payoutDetailsOtherCountries = {
    nameOfAccount: getBlockValue(PAYOUT_DETAILS)?.name,
    bank: getBlockValue(PAYOUT_DETAILS)?.bank,
    swiftCode: getBlockValue(PAYOUT_DETAILS)?.swiftCode,
    bankCode: getBlockValue(PAYOUT_DETAILS)?.bankCode,
    branchCode: getBlockValue(PAYOUT_DETAILS)?.branchCode,
    accountNumber: getBlockValue(PAYOUT_DETAILS)?.accountNumber,
    commission: getBlockValue(COMMISSION)?.percentage,
  };

  const commonObj = {
    spaceOwner: {
      firstName: getBlockValue(LOGIN_DETAILS)?.firstName,
      lastName: getBlockValue(LOGIN_DETAILS)?.lastName,
      country: getBlockValue(LOGIN_DETAILS)?.country,
      phoneNumbers: [getBlockValue(LOGIN_DETAILS)?.phoneNumbers],
      email: getBlockValue(LOGIN_DETAILS)?.email,
    },
    spaceName: getBlockValue(SPACE_DETAILS)?.spaceTitle,
    organizationName: getBlockValue(SPACE_DETAILS)?.organizationName,
    abnNumber: profileListingData?.space_location?.[0]?.spaceCountry == AUSTRALIA ? getBlockValue(SPACE_DETAILS)?.abnNumber : null,
    description: getBlockValue(SPACE_DETAILS)?.description,
    instanceBookingAvailability: getBlockValue(SEATING_AND_COMMON)?.requestToBook,
    parkingAvailability: getBlockValue(SPACE_LOCATION)?.isParkingAvailable,
    nearMrtAvailability: getBlockValue(SPACE_LOCATION)?.mrtStatus,
    nearByLocations: getBlockValue(SPACE_LOCATION)?.mrtSections,
    platform: 'SYSTEM',
    location: {
      address: getBlockValue(SPACE_LOCATION)?.streetName,
      city: getBlockValue(SPACE_LOCATION)?.city,
      country: getBlockValue(SPACE_LOCATION)?.countryCode,
      postalAddress: getBlockValue(SPACE_LOCATION)?.postalAddress,
      postalCode: getBlockValue(SPACE_LOCATION)?.postalCode,
      geoLocation: {
        latitude: getBlockValue(SPACE_LOCATION)?.latitude,
        longitude: getBlockValue(SPACE_LOCATION)?.longitude,
      },
      addressLine1: getBlockValue(SPACE_LOCATION)?.addressLine1,
      addressLine2: getBlockValue(SPACE_LOCATION)?.addressLine2,
      addressLine3: profileListingData?.space_location?.[0]?.spaceCountry == AUSTRALIA ? getBlockValue(SPACE_LOCATION)?.addressLine3+","+ getBlockValue(SPACE_LOCATION)?.postcode : getBlockValue(SPACE_LOCATION)?.addressLine3
    },
    publicTransportAccess: getBlockValue(SPACE_LOCATION)?.publicTransportAccess,
    spaceCurrency: getBlockValue(TAX_INFORMATION)?.currency,
    noticePeriod: getBlockValue(SPACE_AND_NOTICE)?.noticePeriod,
    images: getImageList(),
    payout: profileListingData?.space_location?.[0]?.spaceCountry == AUSTRALIA ? payoutDetailsAus : payoutDetailsOtherCountries,
  };

  const getCommonObj = () => {
    if (getBlockValue(TAX_INFORMATION)?.status) {
      return Object.assign({}, commonObj, {
        ...commonObj,
        taxRegNo: getBlockValue(TAX_INFORMATION)?.registrationNumber,
        applicableTaxes: getTaxDetailsArray(),
      });
    } else {
      return Object.assign({}, commonObj, {
        ...commonObj,
        notTaxRegistered: true,
      });
    }
  };

  const getFinalObject = () => {
    if (getBlockValue(SPACE_AND_NOTICE)?.category?.name === WORK_CAPITALIZED) {
      return Object.assign({}, getCommonObj(), {
        ...getCommonObj(),
        events: [
          {
            eventType: {
              id: getBlockValue(SPACE_AND_NOTICE)?.activity?.[0]?.id,
            },
            seatArrangements: [
              {
                min: 1,
                max: getBlockValue(SEATING_AND_COMMON)?.numberOfSeats,
              },
            ],
            cancellationPolicy: {
              id: getCancellationPolicy(),
            },
            amenities: getAmenities(),
            keepInMind: getKeepInMind(),
            chargings: getChargingTypeArray(),
            spaceAddOns: getAddonDetails(),
          },
        ],
      });
    } else {
      return Object.assign({}, getCommonObj(), {
        ...getCommonObj(),
        spaceArea: { amount: getBlockValue(SEATING_AND_COMMON)?.spaceArea, unit: 'square feet' },
        cancellationPolicy: { id: getCancellationPolicy() },
        pricingOption: getPricingOptionIdList(),
        amenities: getAmenities(),
        seatArrangements: getSeatingArrangementInMeet(),
        keepInMind: getKeepInMind(),
        eventTypes: getEventTypes(),
        excludedEventTypes: getExcludedEventTypes(),
        spaceAddOns: getAddonDetails(),
        chargings: getChargingTypeArray(),
      });
    }
  };

  useEffect(() => {
    if (newHostAuthToken?.token) {
      setCookie('token', newHostAuthToken?.token, { path: '/' });
      dispatch(
        updateReduxWithAuthDetails({
          token: newHostAuthToken?.token,
          isRemoveAuth: false,
          isLoginModalVisible: false,
        })
      );
      dispatch({ type: CLEAR_CONVERT_TO_HOST });
      dispatch({ type: SET_TOKEN_UPDATE_STATUS });
      if (getBlockValue(SPACE_AND_NOTICE)?.category?.name === WORK_CAPITALIZED) {
        dispatch(submitWorkSpaceDetails(getFinalObject()));
      } else {
        dispatch(submitMeetSpaceDetails(getFinalObject()));
      }
    }
  }, [newHostAuthToken]);

  const [showConfirmationPopup, setConfirmationPopupState] = useState(false);

  const goToNext = () => {
    const usersDetails = authDetails ? decode(authDetails) : '';
    if (usersDetails !== '' || usersDetails !== '') {
      if (isSubmit) {
        setConfirmationPopupState(true);
      } else {
        const nextBlock = getProfileData()?.routeList?.indexOf(window.location.pathname) + 1;
        if (nextBlock < 5) {
          dispatch({ type: CLEAR_DATA_SUBMIT, payload: 'error' });
        }
        dispatch(push(getProfileData()?.routeList?.[nextBlock]));
      }
    } else {
      dispatch(changeLoginModalVisibility());
    }
  };

  const submitData = () => {
    const usersDetails = authDetails ? decode(authDetails) : '';
    if (usersDetails?.[AUTHORITIES]?.[0] !== USER_ROLE) {
      setLocalNextBtnDisable(true);
      const nextBlock = getProfileData()?.routeList?.indexOf(window.location.pathname) + 1;
      if (getBlockValue(SPACE_AND_NOTICE)?.category?.name === WORK_CAPITALIZED) {
        dispatch(submitWorkSpaceDetails(getFinalObject()));
      } else {
        dispatch(submitMeetSpaceDetails(getFinalObject()));
      }
      dispatch(push(getProfileData()?.routeList?.[nextBlock]));
    } else {
      dispatch(updateToHost());
    }
  };

  const goToPreviousPath = () => {
    if (backButtonText === BACK_BTN) {
      const nextBlock = getProfileData()?.routeList.indexOf(window.location.pathname) - 1;
      dispatch(push(getProfileData()?.routeList?.[nextBlock]));
    } else if (backButtonText === BACK_TO_HOME) {
      history.push(`/${country}`);
    }
  };

  return (
    <>
      <div className="floating-btn-wrapper">
        <div className="floating-btn-inner-wrapper">
          {backButton && (
            <button className={`btn back-btn ${backBtnClass}`} onClick={goToPreviousPath} id={'nextButton'}>
              <img className="back-icon" src={arrowIcon} alt="back" />
              {backButtonText}
            </button>
          )}
          {frontButton && (
            <button
              className={`btn next-btn ${nextBtnClass}`}
              style={isActive ? {} : { backgroundColor: 'grey', color: 'white' }}
              disabled={!isActive || localNextBtnDisable}
              id={'nextButton'}
              onClick={() => goToNext()}
            >
              {nextButtonText}
            </button>
          )}
        </div>
      </div>

      <DialogContainer
        className="common-popup add-on-popup"
        visible={showConfirmationPopup}
        id={'AddonId'}
        onHide={() => {
          setConfirmationPopupState(false);
        }}
      >
        <h2 className="main-title">Are you sure you want to submit?</h2>
        <div className="btn-wrapper">
          <button
            className="border-btn"
            onClick={() => {
              setConfirmationPopupState(false);
            }}
          >
            No
          </button>
          <button className="gray-btn active" onClick={submitData}>
            Yes
          </button>
        </div>
      </DialogContainer>
    </>
  );
};

export default FlowHandlingButtons;
