import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import { getSpaceReportData } from '../ReportAction';
import {
  spaceTrackerObj,
  searchTextOnFilter,
  searchOnFilter,
  handleNullValues,
  arrayColumnFilter,
  searchFilterForEmptyFields,
  setToolTip
} from '../../../helper/ReportingHelper';
import InfiniteTable from '../../Common/Table/InfiniteTable'
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import YearMonthFilter from '../common/YearMonthFilter';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';

const SpaceTracker = ({ handleDownload, status }) => {
  const spaceTrackerData = useSelector((state) => state?.report?.reports?.space?.data);
  const authToken  = useSelector((state) => state?.auth?.loginDetails?.data?.token);  

  const [dataSource, setDataSource] = useState([]);
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    const formattedData = spaceTrackerObj(spaceTrackerData);
    formattedData?.length && setDataSource(formattedData);   
  }, [spaceTrackerData]);

  const handleSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const spaceTrackerColumns = [
    {
      key: 'spaceId',
      title: 'Space ID',
      dataIndex: 'spaceId',
      width:113,
      className: 'custom-width',
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceId');
      },
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      width:113,
      className: 'custom-width',
      render: text => {
        return text ? <div>{text}<br/> </div>:handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="category"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '68px' }} theme="filled" />,
      onFilter: (value, record) => {
        return arrayColumnFilter(value, record, 'category');
      },
    },
    {
      key: 'activity',
      title: 'Activity',
      dataIndex: 'activity',
      width:113,
      className: 'custom-width',
      render: text => {
        return text ? <div>{text}<br/> </div>:handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="activity"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return arrayColumnFilter(value, record, 'activity'); 
      },
    },
    {
      key: 'organizationName',
      title: 'Organization Name',
      dataIndex: 'organizationName',
      width:113,
      className: 'custom-width',
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '76px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'organizationName');
      },
    },
    {
      key: 'listedDate',
      title: 'Listed Date',
      dataIndex: 'listedDate',
      width:113,
    },
    {
      key: 'listedMonth',
      title: 'Listed Month',
      dataIndex: 'listedMonth',
      className: 'custom-width',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="month"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '50px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'listedMonth');
      },
    },
    {
      key: 'listedYear',
      title: 'Listed Year',
      dataIndex: 'listedYear',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="year"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'listedYear');
      },
    },
    {
      key: 'hostName',
      title: 'Host Name',
      dataIndex: 'hostName',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostEmail',
      title: 'Host Email',
      dataIndex: 'hostEmail',
      className: 'custom-width',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '78px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'hostEmail');
      },
    },
    {
      key: 'hostNumber',
      title: 'Host Tel.#',
      dataIndex: 'hostNumber',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceName',
      title: 'Space Name',
      dataIndex: 'spaceName',
      className: 'custom-width',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '48px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceName');
      },
    },
    {
      key: 'spaceAddress',
      title: 'Space Address',
      dataIndex: 'spaceAddress',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceCity',
      title: 'Space City',
      dataIndex: 'spaceCity',
      className: 'custom-width',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '77px' }}></Icon>,
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceCity');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Space Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width:113,
      render: text => {
        return text === 'LK'? 'SL' : handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'spaceCountry');
      },
    },
    {
      key: 'maxSpaceCapacity',
      title: 'Max. Space Capacity',
      dataIndex: 'maxSpaceCapacity',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'nameOnAccount',
      title: 'Name On Account',
      dataIndex: 'nameOnAccount',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'bank',
      title: 'Bank',
      dataIndex: 'bank',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'swiftCode',
      title: 'Swift Code',
      dataIndex: 'swiftCode',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'bankCode',
      title: 'Bank Code',
      dataIndex: 'bankCode',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'branchCode',
      title: 'Branch Code',
      dataIndex: 'branchCode',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'accountNumber',
      title: 'Account Number',
      dataIndex: 'accountNumber',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'commission',
      title: 'Commission %',
      dataIndex: 'commission',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      className: 'custom-width',
      width:113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="status"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '51px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'status')
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'space-tracker', totalDataLength: dataSource?.length, token: authToken })
  }
  
  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status} />

      <InfiniteTable
        columns={spaceTrackerColumns}
        dataSource={dataSource}
        tableDispatch={getSpaceReportData}
        props={{ increaseValue: 10, token: authToken }}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
      />
    </div>
  );
};

export default withTracker('SPACE_TRACKER') (SpaceTracker);
