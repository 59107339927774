import React from 'react';
import { Route, Switch, useRouteMatch, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';

import SpaceListing from './SpaceListing/SpaceListing';
import HostOnBoarding from './HostOnBoarding/HostOnboarding';
import FeaturedSpaces from './Result/FeaturedSpaces';
import ReportContainer from './Reports/ReportContainer';
import SpaceContainer from './HostDashboard/SpaceContainer';

const Home = React.lazy(() => import('./Home/Home'));
const Result = React.lazy(() => import('./Result/Result'));
const NavBar = React.lazy(() => import('./Common/NavBar/NavBar'));
const Dialog = React.lazy(() => import('./Common/Dialog/Dialog'));
const Booking = React.lazy(() => import('./Booking/Booking'));

const Calendar = React.lazy(() => import('./HostDashboard/Calendar'));
const ManageVisits = React.lazy(() => import('./HostDashboard/ManageVisits'));

const Message = React.lazy(() => import('./Dashboard/Message'));
const Dashboard = React.lazy(() => import('./Dashboard/Dashboard'));
const MyBookings = React.lazy(() => import('./BookingCancelation/MyBookings'));
const loading = React.lazy(() => import('./Common/loading'));
const ProfileAndBooking = React.lazy(() => import('./SpaceProfile/ProfileAndBooking'));
const HelpAndSupport = React.lazy(() => import('./Static-pages/HelpAndSupport/HelpAndSupport'));
const AboutUs = React.lazy(() => import('./Static-pages/AboutUs/AboutUs'));
const TermsAndConditions = React.lazy(() => import('./Static-pages/TermAndConditions/TermsAndConditions'));
const ForgotPassword = React.lazy(() => import('./Common/NavBar/Login/forgot-password/ForgotPassword'));
const EmailConfirmation = React.lazy(() => import('./Account-Verification/Email-Verification/EmailConfirmation'));

const AppRoutes = () => {
    const pathName = useSelector((state) => state?.router?.location?.pathname || {});

    const {path} = useRouteMatch();
    const {country} = useParams();

    return (
        <div>
            {pathName !== `/${country}` ? <NavBar isHomePage={false} /> : null}
            <Switch>
                <Route exact path={path} component={Home} />
                <Route path={`${path}/space`} component={ProfileAndBooking} />
                <Route path={`${path}/featured-space`} component={FeaturedSpaces} />
                <Route path={`${path}/:city?/spaces`} component={Result} />
                <Route path="/booking" component={Booking} />

                <Route path={`${path}/dashboard/myspaces`} >
                  <SpaceContainer/>
                </Route>
                <Route path={`${path}/dashboard/calendar`} component={Calendar} />
                <Route path={`${path}/dashboard/list-a-space`} component={SpaceListing} />
                <Route path={`${path}/dashboard/host-onboarding`} component={HostOnBoarding} />
                <Route path={`${path}/dashboard/visits`} component={ManageVisits} />

                <Route path={`${path}/message`} component={Message} />
                <Route path={`${path}/dialog`} component={Dialog} />
                <Route path={`${path}/loading`} component={loading} />

                <Route path={`${path}/dashboard/reports`} component={ReportContainer} />

                <Route path={`${path}/dashboard/mybooking`} key={'reservation'}>
                  <MyBookings componentName="mybookings" title={"My bookings"} />
                </Route>
                <Route path={`${path}/dashboard/reservations`}  key={'mybooking'}>
                  <MyBookings componentName="reservations" title={"Reservations"}/>
                </Route>

                <PrivateRoute path={`${path}/dashboard`} component={Dashboard} />
                <Route path={`${path}/about-us`} component={AboutUs} />
                <Route path={`${path}/help`} component={HelpAndSupport} />
                <Route path={`${path}/terms`} component={TermsAndConditions} />
                <Route path={`${path}/forgotpassword/:forgotPasswordToken?`} component={ForgotPassword} />
                <Route path={`${path}/emailconfirmation/:verificationToken?`} component={EmailConfirmation} />
                </Switch>

        </div>
    )
};

export default AppRoutes;
