import React, { useEffect, useState } from 'react';
import { TextField } from 'react-md';
import Select from 'antd/es/select';
import FlowHandlingButtons from '../Common/HandleNavigationAndSubmit';
import { useDispatch, useSelector } from 'react-redux';
import greenTickIcon from '../../../assets/images/common/green-tick-icon.svg';
import { getProfileData, setProfileData } from '../Common/DataController';
import { spaceListingData } from '../Common/ProfileListingDataModal';
import {
  BLOCK_LOWER,
  BLOCK_MEET_URLS,
  BLOCK_URLS,
  DAILY_MEET_URLS,
  DAILY_URLS,
  DAY_LOWER,
  DEFAULT_DATA_OBJ,
  ERROR_NUMBER_EXCEEDED,
  ERROR_ONLY_NUMBERS,
  HOUR_LOWER,
  HOURLY_MEET_URLS,
  HOURLY_URLS,
  MEETING_SPACE_CODE,
  MONTH_LOWER,
  MONTHLY_URLS,
  seatingArrangementUrls,
  SPACE_AND_NOTICE,
  WEEKLY_LOWER,
  WEEKLY_MEET_URLS,
  WEEKLY_URLS
} from '../SpaceListingConstants';
import { BLOCK, DAILY, HOURLY, MEET_CAPITALIZED, MONTHLY, WEEKLY, WORK_CAPITALIZED } from '../../Common/constants';
import { contentIsNotNull } from '../Common/Validator';
import { showErrorMessage } from '../Common/Common';
import { IMAGES_BASE_URL } from '../../../settings';
import { getPricingOptionsDayArray } from '../ListingAction';

const SeatingAndCommon = ({ currentBlock }) => {
  const dispatch = useDispatch();
  const country = useSelector((state) => state?.locationMeta?.data?.country_longCode?.replace(' ', '-') || null);
  const { Option } = Select;
  const [seatingAndCommonDetails, setSeatingAndCommonDetails] = useState(getProfileData()?.[DEFAULT_DATA_OBJ] ? getProfileData() : spaceListingData(country));
  const pricingOption = useSelector((state) => state?.spaceListing?.pricingOption || []);
  const cancellationPolicy = useSelector((state) => state?.spaceListing?.cancellationPolicy || []);
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const [isRequestToBookAllowed, setIsRequestToBookAllowed] = useState(true);


  const hanldeRequesToBook = (value) => {
    setIsRequestToBookAllowed(value);
    handleSeatingAndCommonDetails('requestToBook', value)
  }

  useEffect(()=>{
    if (seatingAndCommonDetails?.[SPACE_AND_NOTICE]?.[0]?.['activity']?.[0]?.code === "WORK_PRIV"){
      hanldeRequesToBook(false);
    } else {
      if(getFieldCurrentValue('pricingOptions').includes(MONTH_LOWER))
      {
        hanldeRequesToBook(false);
      }else{
        setIsRequestToBookAllowed(true);
      }
    }
  },[])

  useEffect(() => {
    if (pricingOption && getFieldCurrentValue('pricingOptionRaw')?.length === 0) {
      handleSeatingAndCommonDetails('pricingOptionRaw', pricingOption);
    }
    if (cancellationPolicy && getFieldCurrentValue('cancellationPolicyRaw')?.length === 0) {
      handleSeatingAndCommonDetails('cancellationPolicyRaw', cancellationPolicy);
    }
  }, [pricingOption, cancellationPolicy]);

  useEffect(() => {
    if (isTypeWork()) {
      setIsNextBtnActive(
        contentIsNotNull(getFieldCurrentValue('cancellationPolicy'), 0) && contentIsNotNull(getFieldCurrentValue('pricingOptions'), 0) && getFieldCurrentValue('numberOfSeats') > 0 && getFieldCurrentValue('numberOfSeats') < 1000
      );
    } else {
      setIsNextBtnActive(
        contentIsNotNull(getFieldCurrentValue('cancellationPolicy'), 0) &&
        contentIsNotNull(getFieldCurrentValue('pricingOptions'), 0) &&
        getFieldCurrentValue('numberOfSeats') > 0 &&
        getFieldCurrentValue('numberOfSeats') < 1000 &&
        getFieldCurrentValue('spaceArea') > 0
      );
    }
  }, [seatingAndCommonDetails]);

  const [managedPricingOptions, setManagedPricingOptions] = useState(pricingOption);

  const handleSeatingAndCommonDetails = (name, newValue, onlyNumbers = false, maxNumber) => {
    if (onlyNumbers) {
      newValue = Number(newValue.replace(/^0+/, ''));
    }
    if (onlyNumbers && !/^\d+$/.test(newValue) && newValue !== '') {
      showErrorMessage(ERROR_ONLY_NUMBERS);
    } else if (onlyNumbers && maxNumber && Number(newValue) > maxNumber) {
      showErrorMessage(ERROR_NUMBER_EXCEEDED);
    } else {
      const tempObj = Object.assign({}, seatingAndCommonDetails, {
        ...seatingAndCommonDetails,
        processStarted: true,
        [currentBlock]: seatingAndCommonDetails?.[currentBlock].map((value) => {
          value[name] = newValue;
          return value;
        })
      });
      setSeatingAndCommonDetails(tempObj);
      setProfileData(tempObj);
    }
  };

  const handleRouting = (name, newValue) => {
    if(newValue?.length > 0){
      dispatch(getPricingOptionsDayArray(managedPricingOptions?.map(value=> {
        if(newValue.includes(value?.unit)){
          return { id: value?.id, unit:value?.unit , name: value?.name};
        }
      }).filter(value=> value)));
    }

    if (name === 'pricingOptions' 
          && seatingAndCommonDetails?.[SPACE_AND_NOTICE]?.[0]?.['activity']?.[0]?.code !== "WORK_PRIV") {
      if (newValue.includes(MONTH_LOWER)) {
        hanldeRequesToBook(false);
      } else {
        hanldeRequesToBook(true);
      }
    }

    const routingObj = Object.assign({}, seatingAndCommonDetails, {
      ...seatingAndCommonDetails,
      processStarted: true,
      routeList: spaceListingData(country).routeList
    });

    if (newValue.length > 0) {
      let newValueArray = [];
      let enteredValueArray = newValue.length;

      if (getProfileData()?.space_and_notice?.[0]?.category?.name === MEET_CAPITALIZED) {
        newValueArray.push(seatingArrangementUrls(country));
        // if (getProfileData()?.space_and_notice?.[0]?.activity?.length === 1 && getProfileData()?.space_and_notice?.[0]?.activity?.[0]?.code === MEETING_SPACE_CODE) {
        if (getProfileData()?.space_and_notice?.[0]?.activity?.length === 1) {
          while (enteredValueArray--) {
            if (newValue[enteredValueArray] === WEEKLY_LOWER) {
              newValueArray = newValueArray.concat(WEEKLY_URLS(country));
            }
            if (newValue[enteredValueArray] === BLOCK_LOWER) {
              newValueArray = newValueArray.concat(BLOCK_URLS(country));
            }
            if (newValue[enteredValueArray] === HOUR_LOWER) {
              newValueArray = newValueArray.concat(HOURLY_URLS(country));
            }
            if (newValue[enteredValueArray] === DAY_LOWER) {
              newValueArray = newValueArray.concat(DAILY_URLS(country));
            }
          }
        }else{
          //This block will be delete if the routing issue fixed
          while (enteredValueArray--) {
            if (newValue[enteredValueArray] === WEEKLY_LOWER) {
              newValueArray = newValueArray.concat(WEEKLY_URLS(country));
            }
            if (newValue[enteredValueArray] === BLOCK_LOWER) {
              newValueArray = newValueArray.concat(BLOCK_URLS(country));
            }
            if (newValue[enteredValueArray] === HOUR_LOWER) {
              newValueArray = newValueArray.concat(HOURLY_URLS(country));
            }
            if (newValue[enteredValueArray] === DAY_LOWER) {
              newValueArray = newValueArray.concat(DAILY_URLS(country));
            }
          }
        }
        // else {
        //   while (enteredValueArray--) {
        //     if (newValue[enteredValueArray] === WEEKLY_LOWER) {
        //       newValueArray = newValueArray.concat(WEEKLY_MEET_URLS(country));
        //     }
        //     if (newValue[enteredValueArray] === BLOCK_LOWER) {
        //       newValueArray = newValueArray.concat(BLOCK_MEET_URLS(country));
        //     }
        //     if (newValue[enteredValueArray] === HOUR_LOWER) {
        //       newValueArray = newValueArray.concat(HOURLY_MEET_URLS(country));
        //     }
        //     if (newValue[enteredValueArray] === DAY_LOWER) {
        //       newValueArray = newValueArray.concat(DAILY_MEET_URLS(country));
        //     }
        //   }
        // }
      } else {
        while (enteredValueArray--) {
          if (newValue[enteredValueArray] === WEEKLY_LOWER) {
            newValueArray = newValueArray.concat(WEEKLY_URLS(country));
          }
          if (newValue[enteredValueArray] === BLOCK_LOWER) {
            newValueArray = newValueArray.concat(BLOCK_URLS(country));
          }
          if (newValue[enteredValueArray] === HOUR_LOWER) {
            newValueArray = newValueArray.concat(HOURLY_URLS(country));
          }
          if (newValue[enteredValueArray] === DAY_LOWER) {
            newValueArray = newValueArray.concat(DAILY_URLS(country));
          }
          if (newValue[enteredValueArray] === MONTH_LOWER) {
            newValueArray = newValueArray.concat(MONTHLY_URLS(country));
          }
        }
      }

      let newValueArrayLength = newValueArray.length;
      while (newValueArrayLength--) {
        let value = routingObj?.routeList.splice(routingObj?.routeList?.indexOf(`/${country}/dashboard/list-a-space/seating_and_common`) + 1, 0, newValueArray[newValueArrayLength]);
      }
    }
    const tempObj = Object.assign({}, routingObj, {
      ...routingObj,
      processStarted: true,
      [currentBlock]: routingObj?.[currentBlock].map((value) => {
        value[name] = newValue;
        return value;
      })
    });
    setSeatingAndCommonDetails(tempObj);
    setProfileData(tempObj);
  };

  useEffect(() => {
    setManagedPricingOptions(
      pricingOption?.filter((value) => {
        if (isTypeWork()) {
          if (value?.name === WEEKLY || value?.name === DAILY || value?.name === MONTHLY) {
            return true;
          }
        } else {
          return (value?.name === HOURLY && !getFieldCurrentValue('pricingOptions').includes(BLOCK_LOWER)) || (value?.name === BLOCK && !getFieldCurrentValue('pricingOptions').includes(HOUR_LOWER)) || value?.name === DAILY;
        }
      })
    );
  }, [seatingAndCommonDetails?.[currentBlock]?.[0]?.['pricingOptions']]);

  const getFieldCurrentValue = (type) => {
    return seatingAndCommonDetails?.[currentBlock]?.[0]?.[type];
  };

  const isTypeWork = () => {
    return seatingAndCommonDetails?.['space_and_notice']?.[0]?.category?.name === WORK_CAPITALIZED;
  };

  return (
    <>
      <div className="left-side-inner-wrapper seating-details-wrapper">
        <h1
          className="listing-page-title">{isTypeWork() ? 'Seating & common details' : 'Capacity and common details'} </h1>
        <div className="content-wrapper host-content">
          <div className='position-relative'><TextField
            className="input-margin-wrapper"
            id="numberOfSeats"
            label={isTypeWork() ? 'Number of seats (guests)' : 'Max capacity'}
            lineDirection="center"
            errorText="This field is required."
            // type={'number'}
            onChange={(value) => handleSeatingAndCommonDetails('numberOfSeats', value, true, 1000)}
            value={getFieldCurrentValue('numberOfSeats')}
          />
          <span className='superfix seats'>Seats</span>
        </div>  
          {!isTypeWork() && (
            <TextField
              className="input-margin-wrapper"
              id="spaceArea"
              label="Space area (sq. ft)"
              lineDirection="center"
              errorText="This field is required."
              onChange={(value) => handleSeatingAndCommonDetails('spaceArea', value, true)}
              value={getFieldCurrentValue('spaceArea')}
            />
          )}

          <Select
            className={`input-margin-wrapper ${getFieldCurrentValue('cancellationPolicy')?.length > 0 ? 'placeHolderTop' : ''}`}
            dropdownClassName="space-listing-drop-down cancellation-policy"
            placeholder="Cancellation Policy"
            onChange={(value) => handleSeatingAndCommonDetails('cancellationPolicy', value)}
            value={getFieldCurrentValue('cancellationPolicy')}
          >
            {cancellationPolicy.map((value) => (
              <Option key={value?.name || 'Error Occurred'}>
                <div className="option-content-wrapper">
                  <div className="title-description-wrapper">
                    <div className="option-title">{value?.name || 'Error Occurred'}</div>
                    <div className="option-description">{value?.description || 'No Description'}</div>
                  </div>
                  <img className="img" src={greenTickIcon} alt={'Green Tick'}/>
                </div>
              </Option>
            ))}
          </Select>

          {!isTypeWork() && (
            <Select
              className={`input-margin-wrapper ${getFieldCurrentValue('refundableAgainst')?.length > 0 ? 'placeHolderTop' : ''}`}
              dropdownClassName="space-listing-drop-down cancellation-policy"
              placeholder="Refundable against F&B?"
              onChange={(value) => handleSeatingAndCommonDetails('refundableAgainst', value)}
              value={getFieldCurrentValue('refundableAgainst')}
            >
              <Option key={false}>No</Option>
              <Option key={true}>Yes</Option>
            </Select>
          )}

          <Select
            className={`input-margin-wrapper pricing-options ${getFieldCurrentValue('pricingOptions')?.length > 0 ? 'placeHolderTop' : ''}`}
            dropdownClassName="space-listing-drop-down"
            mode="multiple"
            allowClear
            placeholder="Pricing Options"
            onChange={(value) => handleRouting('pricingOptions', value)}
            value={getFieldCurrentValue('pricingOptions')}
          >
            {managedPricingOptions.map((value) => (
              <Option key={value?.unit || 'Error Occurred'}>
                <div className="icon-text-wrapper">
                  <img className="icon" src={IMAGES_BASE_URL + value?.icon}/>
                  <div className="text-wrapper">
                    <div className="title"> {value?.name || 'Error Occurred'}</div>
                    <div className="description">{value?.description}</div>
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <hr className="divider"/>
        <Select
          disabled={!isRequestToBookAllowed}
          className={`input-margin-wrapper pricing-options ${(getFieldCurrentValue('requestToBook'))?.toString()?.length > 0 ? 'placeHolderTop' : ''}`}
          dropdownClassName="space-listing-drop-down"
          placeholder="Booking type"
          onChange={(value) => handleSeatingAndCommonDetails('requestToBook', value)}
          value={getFieldCurrentValue('requestToBook')}
        >
          <Option value={true}>
            <div className="icon-text-wrapper">
              <div className="text-wrapper">
                <div className="title">Instant booking</div>
                <div className="description">This allows guests to book the space in real-time. You will get an instant
                  notification once the booking is confirmed.
                </div>
              </div>
            </div>
          </Option>
          <Option value={false}>
            <div className="icon-text-wrapper">
              <div className="text-wrapper">
                <div className="title">Request to book</div>
                <div className="description">
                  If guests are interested in your space(s), we will send you a notification for you to directly follow
                  up with them. This option is best suited for large private offices and event spaces.
                </div>
              </div>
            </div>
          </Option>
        </Select>
      </div>
      <FlowHandlingButtons isActive={isNextBtnActive}/>
    </>
  );
};

export default SeatingAndCommon;
