import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Icon } from 'antd';
import { getRevenueReportData } from '../ReportAction';
import {
  revenueTrackerObj,
  searchTextOnFilter,
  searchOnFilter,
  handleNullValues,
  searchFilterForEmptyFields,
  setToolTip
} from '../../../helper/ReportingHelper';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import YearMonthFilter from '../common/YearMonthFilter';
import DateFilter from '../common/DateFilter';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';

const RevenueTracker = ({ handleDownload, status }) => {
  const [dataSource, setDataSource] = useState([]);
  const [tableData, setTableData] = useState({});
  const revenueData = useSelector((state) => state?.report?.reports?.revenue?.data);
  const authToken  = useSelector((state) => state?.auth?.loginDetails?.data?.token);  

  useEffect(() => {
    const formattedData = revenueTrackerObj(revenueData);
    formattedData?.length && setDataSource(formattedData);
  }, [revenueData]);

  const handleSearch = (setSelectedKeys, confirm, searchText) => {
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleReset = (setSelectedKeys, clearFilters) => {
    setSelectedKeys([]);
    clearFilters();
  };

  const revenueHeaders = [
    {
      key: 'bookingRef',
      title: 'Booking Ref.#',
      dataIndex: 'bookingRef',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'bookingRef');
      },
    },
    {
      key: 'category',
      title: 'Category',
      dataIndex: 'category',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="category"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '68px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'category'); 
      },
    },
    {
      key: 'activity',
      title: 'Activity',
      dataIndex: 'activity',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="activity"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'activity'); 
      },
    },
    {
      key: 'guestName',
      title: 'Guest Name',
      dataIndex: 'guestName',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '47px'}}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'guestName');
      },
    },
    {
      key: 'guestEmail',
      title: 'Guest Email',
      dataIndex: 'guestEmail',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostName',
      title: 'Host Name',
      dataIndex: 'hostName',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostEmail',
      title: 'Host Email',
      dataIndex: 'hostEmail',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'spaceId',
      title: 'Space ID',
      dataIndex: 'spaceId',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceId');
      },
    },
    {
      key: 'spaceName',
      title: 'Space Name',
      dataIndex: 'spaceName',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchText
          placeholder="Search"
          type="text"
          t
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {
        return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '82px' }}></Icon>;
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'spaceName');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: text => {
        return text === 'LK'? 'SL' : handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'spaceCountry');
      },
    },
    {
      key: 'bookingDate',
      title: 'Booking Date',
      dataIndex: 'bookingDate',
      width: 113,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateFilter
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
          onSearch={searchText =>
            handleSearch(setSelectedKeys, confirm, searchText)
          }
        />
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }} />
      ),
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'bookingDate');
      },
    },
    {
      key: 'paymentDate',
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      className: 'custom-width',
      width: 113,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateFilter
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
          onSearch={searchText =>
            handleSearch(setSelectedKeys, confirm, searchText)
          }
        />
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '66px' }} />
      ),
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'paymentDate'); 
      },
    },
    {
      key: 'eventStartDate',
      title: 'Event Start Date',
      dataIndex: 'eventStartDate',
      width: 113,
    },
    {
      key: 'eventEndDate',
      title: 'Event End Date',
      dataIndex: 'eventEndDate',
      width: 113,
    },
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="month"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '50px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'month'); 
      },
    },
    {
      key: 'year',
      title: 'Year',
      dataIndex: 'year',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <YearMonthFilter
          filterType="year"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '39px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'year'); 
      },
    },
    {
      key: 'baseBookingValue',
      title: 'Base Booking Value',
      dataIndex: 'baseBookingValue',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'hostDiscountValue',
      title: 'Host Discount Value',
      dataIndex: 'hostDiscountValue',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'bookingValueAfterHostDiscount',
      title: 'Booking Value After Host Discount',
      dataIndex: 'bookingValueAfterHostDiscount',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'taxPayableToHost',
      title: 'Tax Payable to Host',
      dataIndex: 'taxPayableToHost',
      width: 113,
      render: text => {
        return text ? text.toFixed(2): handleNullValues(text);
      },
    },
    {
      key: 'msDiscountValue',
      title: 'MS Discount Value',
      dataIndex: 'msDiscountValue',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'finalBookingValue',
      title: 'Final Booking Value',
      dataIndex: 'finalBookingValue',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'taxPayableByGuest',
      title: 'Tax Payable by Guest',
      dataIndex: 'taxPayableByGuest',
      width: 113,
      render: text => {
        return text ? text.toFixed(2): handleNullValues(text);
      },
    },
    {
      key: 'finalGuestPaymentAmount',
      title: 'Final Guest Payment Amount',
      dataIndex: 'finalGuestPaymentAmount',
      width: 113,
      render: text => {
        return text ? text.toFixed(2): handleNullValues(text);
      },
    },
    {
      key: 'transactionFee',
      title: 'Transaction Fee',
      dataIndex: 'transactionFee',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'msCommission',
      title: 'MS Commission %',
      dataIndex: 'msCommission',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'commissionValue',
      title: 'Commission Value',
      dataIndex: 'commissionValue',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'finalCommissionValue',
      title: 'Final Commission Value',
      dataIndex: 'finalCommissionValue',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'totalPayableToHost',
      title: 'Total Payable To Host',
      dataIndex: 'totalPayableToHost',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'grossProfit',
      title: 'Gross Profit',
      dataIndex: 'grossProfit',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
    },
    {
      key: 'paymentStatus',
      title: 'Payment Status',
      dataIndex: 'paymentStatus',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="paymentStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '66px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'paymentStatus');
      },
    },
    {
      key: 'bookingStatus',
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="bookingStatus"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '63px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'bookingStatus');
      },
    },
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'revenue-tracker', totalDataLength: dataSource?.length, token: authToken })
  }

  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status} />

      <InfiniteTable
        columns={revenueHeaders}
        dataSource={dataSource}
        tableDispatch={getRevenueReportData}
        props={{ increaseValue: 10, token: authToken }}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
      />
    </div>
  );
};

export default withTracker('REVENUE_TRACKER') (RevenueTracker);
