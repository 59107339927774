import * as  actions from './ReportActionTypes';
import { addOrUppdateArray } from '../../helper/ReportingHelper';

export const ReportReducer = (
  state = {
    reports: {
      booking: {
        fetching: false,
        error: { status: false, message: '' },
        data: []
      },
      payment: {
        fetching: false,
        error: { status: false, message: '' },
        data: []
      },
      revenue: {
        fetching: false,
        error: { status: false, message: '' },
        data: []
      },
      refund: {
        fetching: false,
        error: { status: false, message: '' },
        data: []
      },
      space: {
        fetching: false,
        error: { status: false, message: '' },
        data: []
      },
      user: {
        fetching: false,
        error: { status: false, message: '' },
        data: []
      },
      summary: {
        fetching: false,
        error: { status: false, message: '' },
        data: []
      }
    },
    reportStatus: {
      fetching: false,
      error: { status: false, message: '' },
      data: []
    },
    availableReports: {
      fetching: false,
      error: { status: false, message: '' },
      data: []
    }
  },
  action
) => {
  switch (action.type) {
    case actions.FETCH_BOOKING_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          booking: {
            ...state.reports.booking,
            fetching: true,
          }
        }
      }
    case actions.FETCH_BOOKING_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          booking: {
            ...state.reports.booking,
            fetching: false,
            error: { status: false, message: '' },
            data: state.reports?.booking?.data?.concat(action.payload)
          }
        }
      }

    case actions.FETCH_BOOKING_REPORT_DATA_FAILS:
      return {
        ...state,
        reports: {
          ...state.reports,
          booking: {
            ...state.reports.booking,
            fetching: false,
            error: { status: true, message: action.payload }
          }
        }
      }

    case actions.FETCH_PAYMENT_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          payment: {
            ...state.reports.payment,
            fetching: true,
          }
        }
      }

    case actions.FETCH_PAYMENT_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          payment: {
            ...state.reports.payment,
            fetching: false,
            error: { status: false, message: '' },
            data: state.reports?.payment?.data?.concat(action.payload)
          }
        }
      }

    case actions.FETCH_PAYMENT_REPORT_DATA_FAILS:
      return {
        ...state,
        reports: {
          ...state.reports,
          payment: {
            ...state.reports.payment,
            fetching: false,
            error: { status: true, message: action.payload }
          }
        }
      }

    case actions.FETCH_REVENUE_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          revenue: {
            ...state.reports.revenue,
            fetching: true,
          }
        }
      }

    case actions.FETCH_REVENUE_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          revenue: {
            ...state.reports.revenue,
            fetching: false,
            error: { status: false, message: '' },
            data: state.reports?.revenue?.data?.concat(action.payload)
          }
        }
      };

    case actions.FETCH_REVENUE_REPORT_DATA_FAILS:
      return {
        ...state,
        reports: {
          ...state.reports,
          revenue: {
            ...state.reports.revenue,
            fetching: false,
            error: { status: true, message: action.payload }
          }
        }
      }

    case actions.FETCH_PAYMENT_REFUND_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          refund: {
            ...state.reports.refund,
            fetching: true,
          }
        }
      }

    case actions.FETCH_PAYMENT_REFUND_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          refund: {
            ...state.reports.refund,
            fetching: false,
            error: { status: false, message: '' },
            data: state.reports?.refund?.data?.concat(action.payload)
          }
        }
      };

    case actions.FETCH_PAYMENT_REFUND_REPORT_DATA_FAILS:
      return {
        ...state,
        reports: {
          ...state.reports,
          refund: {
            ...state.reports.refund,
            fetching: false,
            error: { status: true, message: action.payload }
          }
        }
      }

    case actions.FETCH_SPACE_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          space: {
            ...state.reports.space,
            fetching: true,
          }
        }
      }

    case actions.FETCH_SPACE_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          space: {
            ...state.reports.space,
            fetching: false,
            error: { status: false, message: '' },
            data: state.reports?.space?.data?.concat(action.payload)
          }
        }
      };

    case actions.FETCH_SPACE_REPORT_DATA_FAILS:
      return {
        ...state,
        reports: {
          ...state.reports,
          space: {
            ...state.reports.space,
            fetching: false,
            error: { status: true, message: action.payload }
          }
        }
      }

    case actions.FETCH_USER_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          user: {
            ...state.reports.user,
            fetching: true,
          }
        }
      }

    case actions.FETCH_USER_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          user: {
            ...state.reports.user,
            fetching: false,
            error: { status: false, message: '' },
            data: state.reports?.user?.data?.concat(action.payload)
          }
        }
      }

    case actions.FETCH_USER_REPORT_DATA_FAILS:
      return {
        ...state,
        reports: {
          ...state.reports,
          user: {
            ...state.reports.user,
            fetching: false,
            error: { status: true, message: action.payload },
          }
        }
      }

    case actions.FETCH_SUMMARY_REPORT_DATA:
      return {
        ...state,
        reports: {
          ...state.reports,
          summary: {
            ...state.reports.summary,
            fetching: true,
          }
        }
      }

    case actions.FETCH_SUMMARY_REPORT_DATA_SUCCESS:
      return {
        ...state,
        reports: {
          ...state.reports,
          summary: {
            ...state.reports.summary,
            fetching: false,
            error: { status: false, message: '' },
            data: state.reports?.summary?.data?.concat(action.payload)
          }
        }
      }

    case actions.FETCH_SUMMARY_REPORT_DATA_FAILS:
      return {
        ...state,
        reports: {
          ...state.reports,
          summary: {
            ...state.reports.summary,
            fetching: false,
            error: { status: true, message: action.payload }
          }
        }
      }

    case actions.FETCH_REPORT_STATUS:
      return {
        ...state,
        reportStatus: {
          ...state.reportStatus,
          fetching: true,
          error: { status: false, message: '' },
          data: state?.reportStatus?.data || []
        }
      }

    case actions.FETCH_REPORT_STATUS_SUCCESS:
      return {
        ...state,
        reportStatus: {
          ...state.reportStatus,
          fetching: false,
          error: { status: false, message: '' },
          data: addOrUppdateArray(state?.reportStatus?.data || [], action.payload, 'id')
        }
      }

    case actions.FETCH_REPORT_STATUS_FAILS:
      return {
        ...state,
        reportStatus: {
          ...state.reportStatus,
          fetching: false,
          error: { status: true, message: action.payload },
          data: []
        }
      }

    case actions.FETCH_AVAILABLE_REPORTS:
      return {
        ...state,
        availableReports: {
          ...state.availableReports,
          fetching: true,
          error: { status: false, message: '' },
          data: state?.availableReports?.data || []
        }
      }

    case actions.FETCH_AVAILABLE_REPORTS_SUCCESS:
      return {
        ...state,
        availableReports: {
          ...state.availableReports,
          fetching: false,
          error: { status: false, message: '' },
          data: state.availableReports.data.concat(action.payload.results)
        }
      }

    case actions.FETCH_AVAILABLE_REPORTS_FAILS:
      return {
        ...state,
        availableReports: {
          ...state.availableReports,
          fetching: false,
          error: { status: false, message: '' },
          data: state?.availableReports?.data || []
        }
      }
    case actions.CLEAR_AVAILABLE_REPORTS:
      return {
        ...state,
        availableReports: {
          ...state.availableReports,
          fetching: false,
          error: { status: false, message: '' },
          data: []
        }
      }

    case actions.CLEAR_COMPLETED_TRACKER_STATUS:
      return {
        ...state,
        reportStatus: {
          ...state.reportStatus,
          fetching: false,
          error: { status: false, message: '' },
          data: state?.reportStatus?.data.filter(report => report.type !== action.payload)
        }
      }
    default:
      return state;
  }
};
