import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';
import InfiniteTable from '../../Common/Table/InfiniteTable';
import { getUserTrackerData } from '../ReportAction';
import {
  userTrackerObj,
  searchTextOnFilter,
  searchOnFilter,
  handleNullValues,
  arrayColumnFilter,
  searchFilterForEmptyFields,
  setToolTip
} from '../../../helper/ReportingHelper';
import SearchText from '../common/SearchText';
import SearchFilters from '../common/SearchFilters';
import DateFilter from '../common/DateFilter';
import withTracker from '../HOCs/withTracker';
import HeaderButton from '../common/HeaderButton';

const UserTracker = ({ handleDownload, status }) => {
  const [dataSource, setDataSource] = useState([]);
  const [tableData, setTableData] = useState({});

  const authToken = useSelector((state) => state?.auth?.loginDetails?.data?.token);
  const userTrackerData = useSelector((state) => state?.report?.reports?.user?.data);

  useEffect(() => {
    const formattedData = userTrackerObj(userTrackerData);
    formattedData?.length && setDataSource(formattedData);
  },[userTrackerData]);

  const handleSearch = (setSelectedKeys, confirm, searchText) => {    
    setSelectedKeys([searchText]);
    confirm();
  };

  const handleReset = (setSelectedKeys, clearFilters) => {    
    setSelectedKeys([]);
    clearFilters();
  };

  const userTrackerHeader = [
    {
      key: 'firstName',
      title: 'First Name',
      dataIndex: 'firstName',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>  ( 
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {     
       return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'firstName');
      },
    },
    {
      key: 'lastName',
      title: 'Last Name',
      dataIndex: 'lastName',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>  ( 
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {     
       return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'lastName');
      },
    },
    {
      key: 'email',
      title: 'Email Address',
      dataIndex: 'email',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>  ( 
        <SearchText
          placeholder="Search"
          type="text"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {     
       return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'email');
      },
    },
    {
      key: 'spaceCountry',
      title: 'Country',
      dataIndex: 'spaceCountry',
      className: 'custom-width',
      width: 113,
      render: text => {
        return text === 'LK'? 'SL' : handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="country"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '61px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'spaceCountry');
      },
    },
    {
      key: 'telephoneNumber',
      title: 'Telephone Number',
      dataIndex: 'telephoneNumber',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>  ( 
        <SearchText
          placeholder="Search"
          type="number"
          onSearch={(searchText) => handleSearch(setSelectedKeys, confirm, searchText)}
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
        />
      ),
      filterIcon: (filtered) => {     
       return <Icon type="search" title="Search" style={{ color: filtered ? '#1890ff' : undefined, left: '62px' }}></Icon>
      },
      onFilter: (value, record) => {
        return searchTextOnFilter(value, record, 'telephoneNumber');
      },
    },
    {
      key: 'role',
      title: 'Role',
      dataIndex: 'role',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="role"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return arrayColumnFilter(value, record, 'role');
      },
    },
    {
      key: 'host',
      title: 'Host',
      dataIndex: 'host',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="other"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'host');
      },
    },
    {
      key: 'signUpVia',
      title: 'Sign Up Via',
      dataIndex: 'signUpVia',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="signUpMode"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'signUpVia');
      },
    },
    {
      key: 'dateJoined',
      title: 'Date Joined',
      dataIndex: 'dateJoined',
      className: 'custom-width',
      width: 113,
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <DateFilter
          onReset={() => handleReset(setSelectedKeys, clearFilters)}
          onSearch={searchText =>
            handleSearch(setSelectedKeys, confirm, searchText)
          }
        />
      ),
      filterIcon: filtered => (
        <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined, left: '65px' }} />
      ),
      onFilter: (value, record) => {
        return searchOnFilter(value, record, 'dateJoined');
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: text => {
        return handleNullValues(text);
      },
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchFilters
          filterType="status"
          onChange={(filterValue) => {
            if (filterValue.length) {
              handleSearch(setSelectedKeys, confirm, filterValue);
            } else {
              handleReset(setSelectedKeys, clearFilters);
            }
          }}
        />
      ),
      filterIcon: (filtered) => <Icon type="filter" style={{ color: filtered ? '#1890ff' : undefined, left: '59px' }} theme="filled" />,
      onFilter: (value, record) => {
        return searchFilterForEmptyFields(value, record, 'status')
    },
  }
  ];

  const downloadCSV = () => {
    handleDownload({ ...tableData, reportType: 'user-tracker', totalDataLength: dataSource?.length, token: authToken })
  }

  return (
    <div>
      <HeaderButton handleDownload={downloadCSV} status={status}/>

      <InfiniteTable
        columns={userTrackerHeader}
        dataSource={dataSource}
        tableDispatch={getUserTrackerData}
        props={{ increaseValue: 10, token: authToken }}
        tableData={setTableData}
        scroll={{ y: 330, x: 500 }}
      />
    </div>
  );
};

export default withTracker('USER_TRACKER') (UserTracker);
